import { Component, OnInit } from '@angular/core'

import { locale as en } from './i18n/en'
import { locale as hu } from './i18n/hu'

import { CoreTranslationService } from '@core/services/translation.service'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  /**
   *
   * @param {CoreTranslationService} _coreTranslationService
   */
  constructor(private _coreTranslationService: CoreTranslationService) {
    this._coreTranslationService.translate(hu, en)
  }

  public contentHeader: object

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.contentHeader = {
      headerTitle: 'Kezdőlap',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
        ]
      }
    }
  }
}
