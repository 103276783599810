import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { AuthenticationService } from 'app/auth/service';
import {LoadingService} from "../../main/service/loading.service";
import {finalize} from "rxjs/operators";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private totalRequests = 0;
  private completedRequests = 0;

  /**
   *
   * @param {AuthenticationService} _authenticationService
   * @param _loadingService
   */
  constructor(private _authenticationService: AuthenticationService, private _loadingService: LoadingService) {}

  /**
   * Add auth header with jwt if user is logged in and request is to api url
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this._loadingService.show();
    this.totalRequests++;
    const currentUser = this._authenticationService.currentUserValue;
    const currentUserToken = this._authenticationService.currentUserTokenValue;
    const isLoggedIn = currentUser && currentUserToken;
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUserToken}`
        }
      });
    }

    return next.handle(request).pipe(
      finalize(() => {
        this.completedRequests++;
        if (this.completedRequests === this.totalRequests) {
          this._loadingService.hide();
          this.completedRequests = 0;
          this.totalRequests = 0;
        }
      })
    );
  }
}

export const jwtInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
];
