export const locale = {
    lang: 'hu',
    data: {
        MEMBER: {
            ACTIONS: 'Műveletek',
            ADD_NEW_RECORDS: 'Új tag',
            EXPORT_CSV: 'Exportálás CSV-be',
            SHOW: 'Mutat',
            ENTRIES: 'bejegyzést',
            SEARCH: 'Keresés',
            SEARCH_TYPE: 'Név vagy Tagazonosító',
        },
        TABLE_HEADER: {
            ID: 'Id',
            MEMBER_ID: 'Tagazonosító',
            STATUS: 'Státusz',
            FULL_NAME: 'Név',
            NAME_PREFIX: 'Előtag',
            LASTNAME: 'Vezetéknév',
            FIRSTNAME: 'Keresztnév',
            EMAIL: 'E-mail',
            PHONE: 'Telefonszám',
            BIRTH_NAME: 'Születési név',
            MOTHERS_NAME: 'Anyja neve',
            BIRTH_PLACE: 'Születési hely',
            BIRTH_DATE: 'Születési dátum',
            TAX_ID_NUMBER: 'Adószám',
            PRIME_NUMBER: 'Törzsszám',
            DELETION_REASON: 'A törlés oka',
            ADDRESS_TYPE: 'Típus',
            ZIP_CODE: 'Irsz',
            ZIP_CODE_LONG: 'Irányítószám',
            COUNTRY: 'Ország',
            CITY: 'Város',
            ADDRESS: 'Cím',
            STREET: 'Utca',
            NUMBER: 'Házszám',
            BUILDING: 'Épület',
            STAIRCASE: 'Lépcsőház',
            FLOOR: 'Emelet',
            DOOR: 'Ajtó',
            CREATED_AT: 'Létrehozva',
            UPDATED_AT: 'Módosítva',
            DELETED_AT: 'Törölve',
            ACTIONS: 'Műveletek',
            ADDRESS_INFORMATIONS: 'A cím adatai',
            SEARCH_HELP: '',
        },
        ADDRESS: {
            ACTIONS: 'Műveletek',
            ADD_NEW_RECORDS: 'Új cím',
            EXPORT_CSV: 'Exportálás CSV-be',
            SHOW: 'Mutat',
            ENTRIES: 'bejegyzést',
            SEARCH: 'Keresés',
            SEARCH_TYPE: 'Város vagy Irányítószám',
        },
    }
};
