import {User} from "./user";

export class Address {
	"id": number;
	"address_type": string;
	"user": User;
	"user_id": number;
	"user_name": string;
	"country": string;
	"zip_code": string;
	"city": string;
	"street": string;
	"number": string;
	"building": string;
	"staircase": string;
	"floor": string;
	"door": string;
	"created_at": string;
	"updated_at": string;
	"deleted_at": string;
	"avatar" : string;
	"name_prefix": string;
	"lastname": string;
	"firstname": string;

}
