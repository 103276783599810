<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <!-- content-header component -->
        <app-content-header [contentHeader]="contentHeader"></app-content-header>
        <section id="ngx-datatable-address">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ 'ADDRESS.ACTIONS' | translate }}</h4>
                </div>
                <div class="row">
                    <div class="col-12 mb-50">
                        <button (click)="openModal(null,true, false, true)" class="btn btn-primary ml-1" rippleEffect>
                            <i class="mr-0 mr-sm-1" data-feather="plus"></i
                            ><span class="d-none d-sm-inline-block">{{ 'ADDRESS.ADD_NEW_RECORDS' | translate }}</span>
                        </button>
                        <a [data]="exportCSVData" class="btn btn-outline-secondary float-right mr-1" csvLink
                           rippleEffect>{{ 'ADDRESS.EXPORT_CSV' | translate }}</a>
                    </div>
                    <div class="col-md-6 col-12">
                        <div class="d-flex justify-content-between align-items-center m-1">
                            <label class="d-flex align-items-center"
                            >{{ 'ADDRESS.SHOW' | translate }}
                                <select [(ngModel)]="basicSelectedOption" class="form-control mx-25">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                                {{ 'ADDRESS.ENTRIES' | translate }}</label
                            >
                        </div>
                    </div>
                    <div class="col-md-6 col-12 d-flex justify-content-md-end">
                        <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                            <label class="d-flex align-items-center"
                            >{{ 'ADDRESS.SEARCH' | translate }}<input
                                    (keyup)="filterUpdate($event)"
                                    (search)="filterUpdate($event)"
                                    class="form-control ml-25"
                                    placeholder="{{ 'ADDRESS.SEARCH_TYPE' | translate }}"
                                    type="search"
                            /></label>
                        </div>
                    </div>
                </div>
                <ngx-datatable
                        (activate)="onActivate($event)"
                        (select)="onSelect($event)"
                        [columnMode]="ColumnMode.force"
                        [footerHeight]="50"
                        [headerHeight]="40"
                        [limit]="basicSelectedOption"
                        [rowClass]="getRowClass"
                        [rowHeight]="58"
                        [rows]="kitchenSinkRows"
                        [scrollbarH]="true"
                        [selectionType]="SelectionType.checkbox"
                        class="bootstrap core-bootstrap"
                        rowHeight="auto"
                >
                    <!--   Chekbox (Selector)   -->
                    <ngx-datatable-column
                            [canAutoResize]="false"
                            [draggable]="false"
                            [resizeable]="false"
                            [sortable]="false"
                            [width]="50"
                    >
                        <ng-template
                            let-allRowsSelected="allRowsSelected"
                            let-selectFn="selectFn"
                            ngx-datatable-header-template
                        >
                            <div class="custom-control custom-checkbox">
                                <input
                                        (change)="selectFn(!allRowsSelected)"
                                        [checked]="allRowsSelected"
                                        class="custom-control-input"
                                        id="headerChkbxRef"
                                        type="checkbox"
                                />
                                <label class="custom-control-label" for="headerChkbxRef"></label>
                            </div>
                        </ng-template>
                        <ng-template
                            let-isSelected="isSelected"
                            let-onCheckboxChangeFn="onCheckboxChangeFn"
                            let-rowIndex="rowIndex"
                            ngx-datatable-cell-template
                        >
                            <div class="custom-control custom-checkbox">
                                <input
                                        (change)="onCheckboxChangeFn($event)"
                                        [checked]="isSelected"
                                        class="custom-control-input"
                                        id="rowChkbxRef{{ rowIndex }}"
                                        type="checkbox"
                                />
                                <label class="custom-control-label" for="rowChkbxRef{{ rowIndex }}"></label>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [width]="280" name="name" prop="full_name" headerClass="datatable-name-column">
                        <ng-template let-column="column" ngx-datatable-header-template>
                            {{ 'TABLE_HEADER.FULL_NAME' | translate }}
                        </ng-template>
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="d-flex align-items-center">
                                <div *ngIf="row.avatar; else customAvatar">
                                    <div class="avatar mr-1 ml-0 bg-light-primary">
                                        <img
                                                alt="avatar"
                                                class="rounded-circle mr-0"
                                                height="32"
                                                src="{{ frontendUrl + '/profile_pics/' + row.avatar }}"
                                                width="32"
                                        />
                                    </div>

                                </div>
                                <ng-template #customAvatar >
                                    <div class="avatar mr-1 ml-0 bg-light-primary">
                                        <div class="avatar-content">{{ row.lastname + ' ' + row.firstname | initials }}</div>
                                    </div>
                                </ng-template>
                                <div class="cell-line-height">
                                    <p class="font-medium-1 font-weight-bold line-height-1 mb-25">
                                        {{ row.name_prefix }} {{ row.lastname }} {{ row.firstname }}
                                    </p>
                                    <a href="mailto:{{ row.email }}"><span
                                            class="text-muted font-small-2"> {{ row.email }}</span></a>
                                </div>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column [width]="100" name="{{ 'TABLE_HEADER.ADDRESS_TYPE' | translate }}">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div [ngClass]="{
                                        'badge-light-primary': row.address_type == 'A',
                                        'badge-light-info': row.address_type == 'I',
                                        'badge-light-secondary': row.address_type == 'L'
                                    }"
                                 class="badge badge-pill d-flex align-items-left"
                                 ngbTooltip="A cím típusa. (állandó, ideiglenes, levelezési)"
                                 placement="top"
                            >
                                {{ row.address_type == 'A' ? 'állandó' : row.address_type == 'I' ? 'ideiglenes' : row.address_type == 'L' ? 'levelezési' : 'Nincs típus' }}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column class="d-flex justify-content-end" [width]="60" name="{{ 'TABLE_HEADER.ZIP_CODE' | translate }}"
                                          prop="zip_code"></ngx-datatable-column>

                    <ngx-datatable-column [width]="140" name="{{ 'TABLE_HEADER.CITY' | translate }}"
                                          prop="city"></ngx-datatable-column>

                    <ngx-datatable-column [width]="200" name="{{ 'TABLE_HEADER.ADDRESS' | translate }}">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="cell-line-height">
                                {{ row.street }} {{ row.number }}
<!--                                {{ row.building != null ? row.building + ' épület' : '' }}-->
<!--                                {{ row.staircase != null ? row.staircase + ' lépcshz' : '' }}-->
<!--                                {{ row.floor != null ? row.floor + ' em' : '' }}-->
<!--                                {{ row.door != null ? row.door + ' ajtó' : '' }}-->

                            </div>
                        </ng-template>
                    </ngx-datatable-column>


                    <ngx-datatable-column name="{{ 'TABLE_HEADER.UPDATED_AT' | translate }}"
                                          prop="updated_at"></ngx-datatable-column>

                    <ngx-datatable-column [sortable]="false" [width]="120"
                                          name="{{ 'TABLE_HEADER.ACTIONS' | translate }}">
                        <ng-template
                            let-row="row"
                            ngx-datatable-cell-template>
                            <ng-container>
                            <div *ngIf="row.role_id != 1">
                                <div *ngIf="row.deleted_at == null else elseBlockActions" class="d-flex align-items-center">
                                    <button container="body" (click)="openModal(row.id, false, true, true)" class="invisible-button"
                                            ngbTooltip="Cím megtekintése" >
                                        <i class="text-primary cursor-pointer datatable-action-icons"
                                           data-feather="eye"></i>
                                    </button>

                                    <button (click)="openModal(row.id, false, false, true)" class="invisible-button"
                                            ngbTooltip="Cím szerkesztése" container="body">
                                        <i class="text-primary cursor-pointer datatable-action-icons"
                                           data-feather="edit"></i>
                                    </button>
                                    <button (click)="deleteData(row.id)" class="invisible-button"
                                            ngbTooltip="Cím törlése" container="body">
                                        <i class="text-primary cursor-pointer datatable-action-icons"
                                           data-feather="x-octagon"></i>
                                    </button>
                                </div>
                                <ng-template #elseBlockActions ngx-datatable-cell-template>
                                    <ng-container>
                                        <div container="body" ngbTooltip="Ez a felhasználó törölt! Megtekintés.">
                                            <button (click)="openModal(row.id, false, true, true)" class="invisible-button"
                                                    ngbTooltip="Ez a felhasználó törölt! Megtekintés." container="body">
                                                <i class="text-primary cursor-pointer datatable-action-icons"
                                                   data-feather="eye"></i>
                                            </button>
                                        </div>
                                    </ng-container>
                                </ng-template>
                            </div>
                            </ng-container>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </div>
        </section>
    </div>
</div>
