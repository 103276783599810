import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ColumnMode, DatatableComponent, SelectionType} from '@swimlane/ngx-datatable';
import {CoreTranslationService} from '@core/services/translation.service';

import {locale as english} from 'app/main/division/i18n/en';
import {locale as hungarian} from 'app/main/division/i18n/hu';

import {DivisionService} from './division.service';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DivisionEditModalFormComponent} from "./modal-form/division-edit-modal-form.component";

import {CustomToastrComponent} from 'app/main/extensions/toastr/custom-toastr/custom-toastr.component';
import {cloneDeep} from 'lodash';
import {ToastrService, GlobalConfig} from 'ngx-toastr';


@Component({
	selector: 'app-division',
	templateUrl: './division.component.html',
	styleUrls: ['./division.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class DivisionComponent implements OnInit {
	// Private
	private _unsubscribeAll: Subject<any>;
	private tempData = [];
	private readonly options: GlobalConfig;

	// public
	public contentHeader: any;
	public rows: any;
	public selected = [];
	public kitchenSinkRows: any;
	public basicSelectedOption: number = 10;
	public ColumnMode = ColumnMode;
	public SelectionType = SelectionType;
	public exportCSVData;

	@ViewChild(DatatableComponent) table: DatatableComponent;
	@ViewChild('tableRowDetails') tableRowDetails: any;

	// Public Methods
	// -----------------------------------------------------------------------------------------------------
	/**
	 * Search (filter)
	 *
	 * @param event
	 */
	filterUpdate(event) {
		const val = event.target.value.toLowerCase();
		// filter our data
		// update the rows
		this.kitchenSinkRows = this.tempData.filter(function (d) {
			return d.division_name.toLowerCase().indexOf(val) !== -1 || !val;
		});

		this.exportCSVData = this.tempData.filter(function (d) {
			return d.division_name.toLowerCase().indexOf(val) !== -1 || !val;
		});

		// Whenever the filter changes, always go back to the first page
		this.table.offset = 0;
	}

	/**
	 * For ref only, log selected values
	 *
	 * @param selected
	 */
	onSelect({selected}) {
		//console.log('Select Event', selected, this.selected);
		this.selected.splice(0, this.selected.length);
		this.selected.push(...selected);
	}

	/**
	 * For ref only, log activate events
	 *
	 * @param event
	 */
	onActivate(event) {
		//console.log('Activate Event', event);
	}

	/**
	 * Constructor
	 *
	 * @param _divisionsService
	 * @param {CoreTranslationService} _coreTranslationService
	 * @param toastr
	 * @param modalService
	 */
	constructor(
		private _divisionsService: DivisionService,
		private _coreTranslationService: CoreTranslationService,
		private toastr: ToastrService,
		public modalService: NgbModal) {
		this.options = this.toastr.toastrConfig;
		this._unsubscribeAll = new Subject();
		this._coreTranslationService.translate(english, hungarian);
	}

	// Lifecycle Hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit() {
		this._divisionsService.onDivisionChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
			this.rows = response;

			this.tempData = this.rows;
			this.kitchenSinkRows = this.rows;
			this.exportCSVData = this.rows;
		});

		// content header
		this.contentHeader = {
			headerTitle: 'Tagozatok',
			actionButton: true,
			breadcrumb: {
				type: '',
				links: [
					{
						name: 'Kezdőlap',
						isLink: true,
						link: '/'
					},
					{
						name: 'Karbantartó oldalak',
						isLink: false
					},
					{
						name: 'Tagozatok',
						isLink: false
					}
				]
			}
		};
	}

	// **************************** Popup for create and update **************************** //
	openModal(id: number, isAddNew: boolean) {
		// Open modal form component
		const modalRef = this.modalService.open(DivisionEditModalFormComponent, {centered: true, size: "lg"});
		modalRef.componentInstance.id = id;
		modalRef.componentInstance.isAddNew = isAddNew;

		// handle result passing back from modal form component
		modalRef.result
			.then((result) => {
				if (result) {
					if (result.status === true) {
						// Datatable Refresh
						this._divisionsService.getDataTableRows().then($res => {
							this.rows = $res;
						});
						// Toaster
						if (result.crudType == 'c') {
							let title = 'Sikeres létrehozás!';
							let message = 'A tagozat létrejött.';
							this.toastrCustomSuccess(title, message);
						}
						else if (result.crudType == 'u') {
							let title = 'Sikeres módosítás!';
							let message = 'A tagozat módosult.';
							this.toastrCustomSuccess(title, message);
						}
						else if (result.crudType == '') {
							let title = 'Információ!';
							let message = 'Nem történt módosítás.';
							this.toastrCustomInfo(title, message);
						}
					}
					else {
						let title = 'Hiba!';
						let message = result.message;
						this.toastrCustomDanger(title, message);
					}
				}
				else {
					// user click cancel
					let title = 'Információ!';
					let message = 'Nem történt módosítás.';
					this.toastrCustomInfo(title, message);
				}
			})
			.catch(() => {
				// user click outside of the modal form
				let title = 'Információ!';
				let message = 'Nem történt módosítás.';
				this.toastrCustomInfo(title, message);
			});
	}

	// Delete
	deleteData(id) {
		if (confirm("Biztos, hogy törli?")) {
			this._divisionsService.destroy(id).subscribe(() => {
					// Datatable Refresh
					this._divisionsService.getDataTableRows().then($res => {
						this.rows = $res;
					});
					let title = 'Sikeres törlés!';
					let message = 'A tagozat törölve.';
					this.toastrCustomSuccess(title, message);
				},
				(res) => {
					let title = 'Hiba!';
					let message = res.message;
					this.toastrCustomDanger(title, message);
				}
			);
		}
	}

	// **************************** Toastr Service ****************************
	// Init
	toastrInit() {
		const customToastrRef = cloneDeep(this.options);
		customToastrRef.timeOut = 10000;
		customToastrRef.toastComponent = CustomToastrComponent;
		customToastrRef.closeButton = true;
		customToastrRef.tapToDismiss = false;
		customToastrRef.progressBar = true;
		customToastrRef.toastClass = 'toast ngx-toastr';
		return customToastrRef;
	}

	// Success
	toastrCustomSuccess(title, message) {
		const customToastrRef = this.toastrInit();
		this.toastr.success(message, title, customToastrRef);
	}

	// Warning
	toastrCustomWarning(title, message) {
		const customToastrRef = this.toastrInit();
		this.toastr.warning(message, title, customToastrRef);
	}

	// Error
	toastrCustomDanger(title, message) {
		const customToastrRef = this.toastrInit();
		this.toastr.error(message, title, customToastrRef);
	}

	//  Info
	toastrCustomInfo(title, message) {
		const customToastrRef = this.toastrInit();
		this.toastr.info(message, title, customToastrRef);
	}
}
