export const locale = {
    lang: 'hu',
    data: {
        SECTOR: {
            ACTIONS: 'Műveletek',
            ADD_NEW_RECORDS: 'Új ágazat',
            EXPORT_CSV: 'Exportálás CSV-be',
            SHOW: 'Mutat',
            ENTRIES: 'bejegyzést',
            SEARCH: 'Keresés',
        },
        TABLE_HEADER: {
            ID: 'Id',
            SECTOR_NAME: 'Ágazat neve',
            CREATED_AT: 'Létrehozva',
            UPDATED_AT: 'Módosítva',
            DELETED_AT: 'Törölve',
            ACTIONS: 'Műveletek',
        }
    }
};
