<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <!-- content-header component -->
        <app-content-header [contentHeader]="contentHeader"></app-content-header>

        <!-- Default Divider Starts -->
        <section id="default-divider">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Üdvözlünk a Nemzeti Szakszervezet ügyfélkezelő honlapján!</h4>
                        </div>
                        <div class="card-body">
                            <p class="card-text mb-0"><strong>Miben tudunk segíteni?</strong></p><br>
                            <p class="card-text mb-0">
                                A Nemzeti Szakszervezet nem csak egy szakszervezet, hanem egy közösség is, amelyik támogatja és segíti egymást. Ha csatlakozol hozzánk, akkor számos szolgáltatást vehetsz igénybe, amelyek megkönnyítik az életedet és javítják a munkahelyi helyzetedet.
                            </p>
                            <hr/>
                            <p class="card-text mb-0">
                                <strong>A Saját adatok menüpontban kezelheti a regisztrációkor megadott adatait.</strong>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Segítségre van szüksége? Elérhetőségeink:</h4>
                        </div>
                        <div class="card-body">
                            <p class="card-text mb-0"><strong>E-mail címek:</strong></p><br>
                            <ul>
                                <li>Központi e-mail cím: <a href="mailto:info@nesze.org">info@nesze.org</a></li>
                                <li>Szervezés vállalati és médiapartnereknek: <a href="mailto:titkarsag@nisz.org">titkarsag@nisz.org</a></li>
                                <li>Weboldallal kapcsolatos hibák bejelentése: <a href="mailto:web@nesze.org">web@nesze.org</a></li>
                            </ul>
                            <hr/>
                            <p class="card-text mb-0"><strong>Postacím:</strong></p><br>
                            <ul>
                                <li>1476 Budapest, Pf. 178</li>
                            </ul>
                            <hr/>
                            <p class="card-text mb-0"><strong>Iroda:</strong></p><br>
                            <ul>
                                <li>1102 Budapest, Fehér út</li>
                            </ul>
                            <hr/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Default Divider Ends -->

<!--        <section id="card-demo-example">-->
<!--            <div class="row match-height">-->
<!--                <div class="col-md-6 col-lg-4">-->
<!--                    <div class="card">-->
<!--                        <img alt="Card image cap" class="card-img-top" src="assets/images/slider/01.jpg"/>-->
<!--                        <div class="card-body">-->
<!--                            <h4 class="card-title">Card title</h4>-->
<!--                            <p class="card-text">-->
<!--                                Some quick example text to build on the card title and make up the bulk of the card's-->
<!--                                content.-->
<!--                            </p>-->
<!--                            <a class="btn btn-outline-primary" href="javascript:void(0)" rippleEffect>Go somewhere</a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="col-md-6 col-lg-4">-->
<!--                    <div class="card">-->
<!--                        <div class="card-body">-->
<!--                            <h4 class="card-title">Card title</h4>-->
<!--                            <h6 class="card-subtitle text-muted">Support card subtitle</h6>-->
<!--                        </div>-->
<!--                        <img alt="Card image cap" class="img-fluid" src="assets/images/slider/05.jpg"/>-->
<!--                        <div class="card-body">-->
<!--                            <p class="card-text">Bear claw sesame snaps gummies chocolate.</p>-->
<!--                            <a class="card-link pink" href="javascript:void(0)">Card link</a>-->
<!--                            <a class="card-link pink" href="javascript:void(0)">Another link</a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="col-md-6 col-lg-4">-->
<!--                    <div class="card">-->
<!--                        <div class="card-body">-->
<!--                            <h4 class="card-title">Card title</h4>-->
<!--                            <h6 class="card-subtitle text-muted">Support card subtitle</h6>-->
<!--                            <img alt="Card image cap" class="img-fluid my-2" src="assets/images/slider/03.jpg"/>-->
<!--                            <p class="card-text">Bear claw sesame snaps gummies chocolate.</p>-->
<!--                            <a class="card-link pink" href="javascript:void(0)">Card link</a>-->
<!--                            <a class="card-link pink" href="javascript:void(0)">Another link</a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </section>-->
    </div>
</div>
