export const locale = {
  lang: 'en',
  data: {
    MENU: {
      HOME: 'Home',
      USER: 'My profile',
      PAYMENT: 'Payment data',
      EDITOR: {
          SECTION: 'Editor pages',
          MEMBER: 'Members',
          ADDRESS: 'Addresses',
          DIVISION: 'Divisions',
          SECTOR: 'Sectors',
          WORKPLACE: 'Workplaces',
      },
      ADMINISTRATOR: {
        SECTION: 'Administrator pages',
        ROLE: 'Role modification'
      }
    }
  }
}
