import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CoreCommonModule } from '@core/common.module';

import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

import { UserRoleComponent } from './user-role.component';
import { AuthGuard } from "../../auth/helpers";


const routes = [
  {
    path: 'user-role',
    component: UserRoleComponent,
    canActivate: [AuthGuard],
    data: { animation: 'user', roles: [1]}
  },
];

@NgModule({
  declarations: [UserRoleComponent],
  imports: [RouterModule.forChild(routes), ContentHeaderModule, TranslateModule, CoreCommonModule],
  exports: [UserRoleComponent]
})
export class UserRoleModule {}
