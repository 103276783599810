import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { CoreConfigService } from '@core/services/config.service';
import {AuthenticationService} from "../../../../auth/service";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";

@Component({
  selector: 'app-auth-forgot-password-v1',
  templateUrl: './auth-forgot-password-v1.component.html',
  styleUrls: ['./auth-forgot-password-v1.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthForgotPasswordV1Component implements OnInit {
  // Public
  public coreConfig: any;
  public forgotPasswordForm: UntypedFormGroup;
  public submitted = false;
  public clicked = false;

  // Private
  private _unsubscribeAll: Subject<any>;
  private code;
  private errorMessage: any;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   * @param {UntypedFormBuilder} _formBuilder
   * @param _authService
   * @param _toastrService
   * @param _router
   */
  constructor(
    private _coreConfigService: CoreConfigService,
    private _formBuilder: UntypedFormBuilder,
    private _authService: AuthenticationService,
    private _toastrService: ToastrService,
    private _router: Router) {
    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.forgotPasswordForm.controls;
  }

  /**
   * On Submit
   */
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    this._authService.forgotten_password(this.f.email.value).subscribe(
      () => {
        setTimeout(() => {
          this.f.email.disable();

          this._toastrService.success(
            'Küldtünk egy jelszóvisszaállító emailt, a megadott E-mail címre. Kérlek, ellenőrizd a postafiókodat!',
            'Sikeres küldés',
            { toastClass: 'toast ngx-toastr', closeButton: true, disableTimeOut: true}
          );
        }, 200);
      },
      err => {
        this.errorMessage = err.message;
        this.code = err.code;
        if (this.code === 4001)
          this.errorMessage = 'Nincs ilyen E-mail cím regisztrálva a CRM rendszerben!';
        else
          this.errorMessage = 'Hiba történt az adatok feldolgozása során!';

        setTimeout(() => {
          this._toastrService.error(
            this.errorMessage,
            'Sikertelen küldés',
            { toastClass: 'toast ngx-toastr', closeButton: true, disableTimeOut: true}
          );
        }, 200);
      }
    );

  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.forgotPasswordForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });

    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
