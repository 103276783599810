import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from 'app/auth/service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router, private _authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        console.log(err);
        // 403 Forbidden response returned from api
        if ([403].indexOf(err.status) !== -1) {
          this._router.navigate(['/pages/miscellaneous/not-authorized']);
          // Can also logout and reload if needed
          // this._authenticationService.logout();
          // location.reload(true);
        }
        else if ([422].indexOf(err.status) !== -1) {
          err.message = "Nem feldolgozható tartalom! Kérem ellenőrizze, hogy minden mezőt helyesen töltött-e ki! [422]";
        }
        else if ([500].indexOf(err.status) !== -1) {
          err.message = "Szerver hiba! A szerver nem tudja végrehajtani a kérést! [500]";
        }

        if ([400].indexOf(err.status) !== -1) {
          err.code = err.error.code;
          err.message = err.error.message;
        }

        const error = {
          status: err.status,
          code: err.code,
          message: err.message,
          statusText: err.statusText,
          ok: err.ok,
        }

        return throwError(error);
      })
    );
  }
}

export const errorInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
];
