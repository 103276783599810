import { Component, OnInit } from '@angular/core'

import { locale as en } from './i18n/en'
import { locale as hu } from './i18n/hu'

import { CoreTranslationService } from '@core/services/translation.service'

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
    public contentHeader: object

    /**
     *
     * @param {CoreTranslationService} _coreTranslationService
     */
    constructor(private _coreTranslationService: CoreTranslationService) {
        this._coreTranslationService.translate(hu, en)
    }

    // Lifecycle Hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit() {
        this.contentHeader = {
            headerTitle: 'Kezdőlap',
            actionButton: true,
            breadcrumb: {
                type: '',
                links: [
                    {
                        name: 'Kezdőlap',
                        isLink: true,
                        link: '/'
                    },
                    {
                        name: 'Saját adatok',
                        isLink: false
                    }
                ]
            }
        }
    }
}
