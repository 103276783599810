import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {CoreCommonModule} from '@core/common.module';
import {ContentHeaderModule} from 'app/layout/components/content-header/content-header.module';
import {AddressService} from "./address.service";
import {AuthGuard} from "../../auth/helpers";
import {AddressComponent} from "./address.component";
import {RouterModule, Routes} from "@angular/router";
import {CommonModule} from "@angular/common";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {CsvModule} from "@ctrl/ngx-csv";
import {CoreCardModule} from "../../../@core/components/core-card/core-card.module";
import {BrowserModule} from "@angular/platform-browser";
import {ToastrModule} from "ngx-toastr";
import {AddressEditModalFormComponent} from "./modal-form/address-edit-modal-form.component";
import {NgSelectModule} from "@ng-select/ng-select";


const routes: Routes = [
	{
		path: 'address',
		component: AddressComponent,
		canActivate: [AuthGuard],
		data: {animation: 'address', roles: [1, 2]},
		resolve: {
			profile: AddressService
		}
	}
];

@NgModule({
	declarations: [AddressComponent, AddressEditModalFormComponent],
	imports: [
		CommonModule,
		BrowserModule,
		RouterModule.forChild(routes),
		NgbModule, CoreCommonModule,
		ContentHeaderModule,
		TranslateModule,
		NgxDatatableModule,
		CsvModule,
		CoreCardModule,
		ToastrModule, NgSelectModule
	],
	providers: [AddressService]
})
export class AddressModule {
}

