export const locale = {
    lang: 'hu',
    data: {
        AUTHENTICATION: {
            CARD_TITLE: 'Bejelentkezés',
            CARD_TEXT: 'Kérjük, adja meg E-mail címét és jelszavát.',
            EMAIL_LABEL: 'E-mail',
            EMAIL_MESSAGE_REQUIRED: 'Az E-mail cím megadása kötelező',
            EMAIL_MESSAGE_INVALID: 'Érvénytelen E-mail cím',
            PASSWORD_LABEL: 'Jelszó',
            FORGOT_PASSWORD_LABEL: 'Elfelejtete jelszavát?',
            PASSWORD_REQUIRED_MESSAGE: 'A jelszó megadása kötelező',
            REMEMBER_ME_LABEL: 'Belépve maradok',
            SIGN_IN_BUTTON: 'Belépés',
            NEW_ON_OUR_PLATFORM: 'Nincs még felhasználója?',
            REGISTER_LINK: 'Regisztráljon',
            UNAUTHORIZED: 'Hibás felhasználónév vagy jelszó!',
        }
    }
}
