import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {AddressService} from "../address.service";
import {ActivatedRoute} from "@angular/router";
import {CustomToastrComponent} from 'app/main/extensions/toastr/custom-toastr/custom-toastr.component';
import {cloneDeep} from 'lodash';
import {ToastrService, GlobalConfig} from 'ngx-toastr';
import {environment} from "../../../../environments/environment";
import {Address} from "../../../auth/models/address";
import {FilteredMember, MemberService} from "../../member/member.service";


@Component({
	selector: 'app-address-edit.edit-modal',
	templateUrl: './address-edit-modal-form.component.html',
	styleUrls: ['./address-edit-modal-form.component.scss']
})

export class AddressEditModalFormComponent implements OnInit {
	@Input() public id: number;
	@Input() public isAddNew: boolean;
	@Input() public viewOnly: boolean;
	@Input() public isAdmin: boolean;
	@Input() public userId: number;

	private data: any;
	private result: any;
	private readonly options: GlobalConfig;
	public address = new Address();

	public selectMember: FilteredMember[] = [];
	public selectMemberLoading = false;

	// address type icon
	public addressTypeIcon = [
		{
			id: 'A',
			name: 'Állandó',
			icon: 'fa fa-home'
		},
		{
			id: 'I',
			name: 'Ideiglenes',
			icon: 'fa fa-address-book'
		},
		{
			id: 'L',
			name: 'Levelezési',
			icon: 'fa fa-envelope'
		},
	];
	// default address type icon
	public selectedAddressType = this.addressTypeIcon[0].id;
	public frontendUrl: string = `${environment.frontendUrl}`;

	constructor(
		private route: ActivatedRoute,
		private addressService: AddressService,
		private memberService: MemberService,
		private toastr: ToastrService,
		public activeModal: NgbActiveModal,
	) {
		this.options = this.toastr.toastrConfig;
	}

	ngOnInit() {
		// inicializálás - cím szerkesztése
		if (!this.isAddNew) {
			this.getAddress();
		}
		// inicializálás - új cím
		else {
			if (this.isAdmin) {
				this.selectMemberMethod();
			}
			else {
				this.address.user_id = this.userId;
			}
			this.address.country = 'Magyarország';
			this.address.address_type = 'A';
		}
	}

	onSubmit(isValid) {
		// stop here if form is invalid
		if (!isValid || !this.address.user_id) {
			this.toastrCustomDanger('Validációs hiba!', 'Kérem válassza ki a Tagot és töltse ki a kötelező mezőket!');
			return;
		}

		if (this.address && this.address.id) {
			// set the adddress_type from the select
			this.address.address_type = this.selectedAddressType;

			// *** Update *** //
			this.addressService.update(this.id, this.address)
				.subscribe(
					() => {
						this.result = {status: true, crudType: 'u'};
						this.activeModal.close(this.result);
					},
					(res) => {
						this.toastrCustomDanger('Hiba', res.message)
					}
				);
		}
		else {
			// *** Create *** //
			this.addressService.create(this.address)
				.subscribe(
					() => {
						this.result = {status: true, crudType: 'c'};
						this.activeModal.close(this.result);
					},
					(res) => {
						this.toastrCustomDanger('Hiba', res.message)
					}
				);
		}
	}

	// select member fill
	private selectMemberMethod() {
		this.selectMemberLoading = true;
		this.memberService.getFilteredMembers().subscribe(x => {
			this.selectMember = x;
			this.selectMemberLoading = false;
		});
	}

	// select member onchange
	selectMemberChange(event: any) {
		this.address.user_id = event.id;
	}

	selectAddressChange(event: any) {
		this.address.address_type = event.id;
	}

	// Visszaadja a Címet
	private getAddress() {
		this.addressService.getAddressById(this.id).subscribe(
			res => {
				this.data = res;
				if (this.data) {
					this.address = this.data;
					this.selectedAddressType = this.data.address_type;
				}
			});
	}

	// **************************** Toastr Service ****************************
	// Init
	toastrInit() {
		const customToastrRef = cloneDeep(this.options);
		customToastrRef.timeOut = 10000;
		customToastrRef.toastComponent = CustomToastrComponent;
		customToastrRef.closeButton = true;
		customToastrRef.tapToDismiss = false;
		customToastrRef.progressBar = true;
		customToastrRef.toastClass = 'toast ngx-toastr';
		return customToastrRef;
	}

	// Error
	toastrCustomDanger(title, message) {
		const customToastrRef = this.toastrInit();
		this.toastr.error(message, title, customToastrRef);
	}
}
