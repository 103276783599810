import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  {
    id: 'home',
    role: [1, 2, 3],
    title: 'Home',
    translate: 'MENU.HOME',
    type: 'item',
    icon: 'home',
    url: 'home'
  },
  {
    id: 'account-settings',
    role: [1, 2, 3],
    title: 'User',
    translate: 'MENU.USER',
    type: 'item',
    icon: 'user',
    url: 'account-settings',
  },
  {
    id: 'payment',
    role: [1, 2, 3],
    title: 'Payment',
    translate: 'MENU.PAYMENT',
    type: 'item',
    icon: 'credit-card',
    externalUrl: true,
    url: 'https://www.nesze.org/tagdijfizetes',
  },
  // Editor pages TODO ROLES !!! //role: ['Admin'] // To set multiple role: ['Admin', 'Client']
  {
    id: 'editor',
    role: [1, 2],
    type: 'section',
    title: 'Editor Pages',
    translate: 'MENU.EDITOR.SECTION',
    icon: 'package',
    children: [
      {
        id: 'member',
        role: [1, 2],
        title: 'Member',
        translate: 'MENU.EDITOR.MEMBER',
        type: 'item',
        icon: 'users',
        url: 'member'
      },
      {
        id: 'address',
        role: [1, 2],
        title: 'Address',
        translate: 'MENU.EDITOR.ADDRESS',
        type: 'item',
        icon: 'map-pin',
        url: 'address'
      },
      {
        id: 'division',
        role: [1, 2],
        title: 'Division',
        translate: 'MENU.EDITOR.DIVISION',
        type: 'item',
        icon: 'globe',
        url: 'division'
      },
      {
        id: 'workplace',
        role: [1, 2],
        title: 'Workplace',
        translate: 'MENU.EDITOR.WORKPLACE',
        type: 'item',
        icon: 'briefcase',
        url: 'workplace'
      },
      {
        id: 'sector',
        role: [1, 2],
        title: 'Sector',
        translate: 'MENU.EDITOR.SECTOR',
        type: 'item',
        icon: 'columns',
        url: 'sector'
      },
    ]
  },
  // Admin pages TODO ROLES !!! //role: ['Admin'] // To set multiple role: ['Admin', 'Client']
  {
    id: 'administrator',
    role: [1],
    type: 'section',
    title: 'Administrator Pages',
    translate: 'MENU.ADMINISTRATOR.SECTION',
    icon: 'package',
    children: [
      {
        id: 'roles',
        role: [1],
        title: 'Role',
        translate: 'MENU.ADMINISTRATOR.ROLE',
        type: 'item',
        icon: 'tool',
        url: 'user-role'
      }
    ]
  }
]
