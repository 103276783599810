export const locale = {
    lang: 'hu',
    data: {
        MENU: {
            HOME: 'Kezdőlap',
            USER: 'Saját adatok',
            PAYMENT: 'Tagdíj fizetés',
            EDITOR: {
                SECTION: 'Karbantartó oldalak',
                    MEMBER: 'Tagok',
                    ADDRESS: 'Címek',
                    DIVISION: 'Tagozatok',
                    SECTOR: 'Ágazatok',
                    WORKPLACE: 'Munkahelyek',
            },
            ADMINISTRATOR: {
                SECTION: 'Adminisztrátor oldalak',
                    ROLE: 'Szerepkör módosítás'
            }
        }
    }
}
