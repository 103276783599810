import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { CoreConfigService } from '@core/services/config.service';
import {AuthenticationService} from "../../../../auth/service";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-auth-reset-password-v1',
  templateUrl: './auth-reset-password-v1.component.html',
  styleUrls: ['./auth-reset-password-v1.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthResetPasswordV1Component implements OnInit {
  // Public
  public coreConfig: any;
  public passwordTextType: boolean;
  public confPasswordTextType: boolean;
  public resetPasswordForm: UntypedFormGroup;
  public submitted = false;
  private code;
  private errorMessage: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   * @param {UntypedFormBuilder} _formBuilder
   * @param _authService
   * @param _toastrService
   * @param _router
   * @param _route
   */
  constructor(
    private _coreConfigService: CoreConfigService,
    private _formBuilder: UntypedFormBuilder,
    private _authService: AuthenticationService,
    private _toastrService: ToastrService,
    private _router: Router,
    private _route: ActivatedRoute) {
    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.resetPasswordForm.controls;
  }

  /**
   * Toggle password
   */
  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  /**
   * Toggle confirm password
   */
  toggleConfPasswordTextType() {
    this.confPasswordTextType = !this.confPasswordTextType;
  }

  /**
   * On Submit
   */
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.resetPasswordForm.invalid) {
      return;
    }

    this._authService.password_reset(
      this.f.newPassword.value,
      this.f.confirmPassword.value,
      this._route.snapshot.queryParamMap.get('user_id'),
      this._route.snapshot.queryParamMap.get('link')).subscribe(
      () => {
        this._router.navigateByUrl('/login').then(() => setTimeout(() => {
          this._toastrService.success(
            'A jelszónódosítás sikerült. Kérjük, jelentkezz be az új jeszavaddal!',
            'Sikeres jelszócsere!',
            { toastClass: 'toast ngx-toastr', closeButton: true, disableTimeOut: true}
          );
        }, 200));
      },
      err => {
        this.errorMessage = err.message;
        this.code = err.code;
        if (this.code === 4001)
          this.errorMessage = 'Az új jelszó és a jelszó megerősítése mező nem egyezik meg!';
        else if (this.code === 4002)
          this.errorMessage = 'A jeszó-visszaállító kód érvényessége lejárt!';
        else if (this.code === 4003)
          this.errorMessage = 'Ezt a jelszó-visszaállító kódot már felhasználták!';
        else if (this.code === 4004)
          this.errorMessage = 'Az új jelszó nem egyezhet meg a régivel!';
        else if (this.code === 4005)
          this.errorMessage = 'Validációs hiba! Kérem, ellenőrizze a kitöltött mezőket!';
        else
          this.errorMessage = 'Hiba történt az adatok feldolgozása során!';

        setTimeout(() => {
          this._toastrService.error(
            this.errorMessage,
            'Sikertelen jelszóbeállítás',
            { toastClass: 'toast ngx-toastr', closeButton: true, disableTimeOut: true}
          );
        }, 200);
      }
    );
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.resetPasswordForm = this._formBuilder.group({
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    });

    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
