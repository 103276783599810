export const locale = {
    lang: 'hu',
    data: {
        USER: {
            TITLE: 'Saját adatok'
        },
        HOME: {
            HEADERTITLE: 'Kezdőlap'
        },
    }
}
