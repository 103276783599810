<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <!-- content-header component -->
        <app-content-header [contentHeader]="contentHeader"></app-content-header>
        <section id="ngx-datatable-workplace">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ 'WORKPLACE.ACTIONS' | translate }}</h4>
                </div>
                <div class="row">
                    <div class="col-12 mb-50">
                        <button (click)="openModal(null,true)" class="btn btn-primary ml-1" rippleEffect>
                            <i class="mr-0 mr-sm-1" data-feather="plus"></i
                            ><span class="d-none d-sm-inline-block">{{ 'WORKPLACE.ADD_NEW_RECORDS' | translate }}</span>
                        </button>
                        <a [data]="exportCSVData" class="btn btn-outline-secondary float-right mr-1" csvLink rippleEffect>{{ 'WORKPLACE.EXPORT_CSV' | translate }}</a>
                    </div>
                    <div class="col-md-6 col-12">
                        <div class="d-flex justify-content-between align-items-center m-1">
                            <label class="d-flex align-items-center"
                            >{{ 'WORKPLACE.SHOW' | translate }}
                                <select [(ngModel)]="basicSelectedOption" class="form-control mx-25">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                                {{ 'WORKPLACE.ENTRIES' | translate }}</label
                            >
                        </div>
                    </div>
                    <div class="col-md-6 col-12 d-flex justify-content-md-end">
                        <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                            <label class="d-flex align-items-center"
                            >{{ 'WORKPLACE.SEARCH' | translate }}<input
                                    (keyup)="filterUpdate($event)"
                                    (search)="filterUpdate($event)"
                                    class="form-control ml-25"
                                    placeholder="{{ 'WORKPLACE.SEARCH' | translate }}"
                                    type="search"
                            /></label>
                        </div>
                    </div>
                </div>
                <ngx-datatable
                        (activate)="onActivate($event)"
                        (select)="onSelect($event)"
                        [columnMode]="ColumnMode.force"
                        [footerHeight]="50"
                        [headerHeight]="40"
                        [limit]="basicSelectedOption"
                        [rowHeight]="58"
                        [rows]="kitchenSinkRows"
                        [scrollbarH]="true"
                        [selectionType]="SelectionType.checkbox"
                        class="bootstrap core-bootstrap"
                >
                    <!--   Chekbox (Selector)   -->
                    <ngx-datatable-column
                            [canAutoResize]="false"
                            [draggable]="false"
                            [resizeable]="false"
                            [sortable]="false"
                            [width]="50"
                    >
                        <ng-template
                                let-allRowsSelected="allRowsSelected"
                                let-selectFn="selectFn"
                                ngx-datatable-header-template
                        >
                            <div class="custom-control custom-checkbox">
                                <input
                                        (change)="selectFn(!allRowsSelected)"
                                        [checked]="allRowsSelected"
                                        class="custom-control-input"
                                        id="headerChkbxRef"
                                        type="checkbox"
                                />
                                <label class="custom-control-label" for="headerChkbxRef"></label>
                            </div>
                        </ng-template>
                        <ng-template
                                let-isSelected="isSelected"
                                let-onCheckboxChangeFn="onCheckboxChangeFn"
                                let-rowIndex="rowIndex"
                                ngx-datatable-cell-template
                        >
                            <div class="custom-control custom-checkbox">
                                <input
                                        (change)="onCheckboxChangeFn($event)"
                                        [checked]="isSelected"
                                        class="custom-control-input"
                                        id="rowChkbxRef{{ rowIndex }}"
                                        type="checkbox"
                                />
                                <label class="custom-control-label" for="rowChkbxRef{{ rowIndex }}"></label>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
<!--                    <ngx-datatable-column [width]="10" name="{{ 'TABLE_HEADER.ID' | translate }}" prop="id"></ngx-datatable-column>-->
                    <ngx-datatable-column [width]="250" name="{{ 'TABLE_HEADER.WORKPLACE_NAME' | translate }}" prop="workplace_name">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="cell-line-height">
                                <p class="font-medium-1 font-weight-bold line-height-1 mb-25">
                                    {{ row.workplace_name }}
                                </p>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [width]="120" name="{{ 'TABLE_HEADER.CREATED_AT' | translate }}" prop="created_at"></ngx-datatable-column>
                    <ngx-datatable-column [width]="120" name="{{ 'TABLE_HEADER.UPDATED_AT' | translate }}" prop="updated_at"></ngx-datatable-column>
                    <!--<ngx-datatable-column [width]="120" name="{{ 'TABLE_HEADER.DELETED_AT' | translate }}" prop="deleted_at"></ngx-datatable-column>-->

                    <ngx-datatable-column [sortable]="false" [width]="120" name="{{ 'TABLE_HEADER.ACTIONS' | translate }}">
                        <ng-template
                                let-row="row"
                                ngx-datatable-cell-template>
                            <div class="d-flex align-items-center">
                                <button (click)="openModal(row.id, false)" class="invisible-button">
                                    <i class="text-primary cursor-pointer datatable-action-icons" data-feather="edit"></i>
                                </button>
                                <button (click)="deleteData(row.id)" class="invisible-button">
                                    <i class="text-primary cursor-pointer datatable-action-icons" data-feather="x-octagon"></i>
                                </button>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </div>
        </section>
    </div>
</div>
