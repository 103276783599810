export const locale = {
  lang: 'en',
  data: {
    AUTHENTICATION: {
      CARD_TITLE: 'Login',
      CARD_TEXT: 'Please sign-in to your account',
      EMAIL_LABEL: 'E-mail',
      EMAIL_MESSAGE_REQUIRED: 'Email is required',
      EMAIL_MESSAGE_INVALID: 'Email must be a valid email address',
      PASSWORD_LABEL: 'Password',
      FORGOT_PASSWORD_LABEL: 'Forgot password?',
      PASSWORD_REQUIRED_MESSAGE: 'Password is required',
      REMEMBER_ME_LABEL: 'Remember Me',
      SIGN_IN_BUTTON: 'Sign in',
      NEW_ON_OUR_PLATFORM: 'New on our platform?',
      REGISTER_LINK: 'Create an account',
      UNAUTHORIZED: 'Invalid username and password Please try again',
    }
  }
}
