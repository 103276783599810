import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Workplace} from "../../../auth/models/workplace";
import {WorkplaceService} from "../workplace.service";
import {ActivatedRoute} from "@angular/router";

@Component({
	selector: 'app-workplace-edit.edit-modal',
	templateUrl: './workplace-edit-modal-form.component.html',
	styleUrls: ['./workplace-edit-modal-form.component.scss']
})

export class WorkplaceEditModalFormComponent implements OnInit {
	@Input() public id: number;
	@Input() public isAddNew: boolean;

	private data: any;
	private result: any;
	public workplace = new Workplace();

	constructor(
		private route: ActivatedRoute,
		private workplaceService: WorkplaceService,
		public activeModal: NgbActiveModal,
	) {
	}

	ngOnInit() {
		if (!this.isAddNew) {
			this.getWorkplace();
		}
	}

	onSubmit(isValid) {
		// stop here if form is invalid
		if (!isValid) {
			return;
		}

		if (this.workplace && this.workplace.id) {
			// *** Update *** //
			this.workplaceService.update(this.id, this.workplace)
				.subscribe(
					() => {
						this.result = {status: true, crudType: 'u'};
						this.activeModal.close(this.result);
					},
					(res) => {
						this.result = {status: false, message: res};
						this.activeModal.close(this.result);
					}
				);
		}
		else {
			// *** Create *** //
			this.workplaceService.create(this.workplace)
				.subscribe(
					() => {
						this.result = {status: true, crudType: 'c'};
						this.activeModal.close(this.result);
					},
					(res) => {
						this.result = {status: false, message: res};
						this.activeModal.close(this.result);
					}
				);
		}
	}

	private getWorkplace() {
		this.workplaceService.getWorkplaceById(this.id).subscribe(
			res => {
				this.data = res;
				this.workplace = this.data;
		})
	}
}
