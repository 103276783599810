<div class="card">
    <div class="card-header">
        <h4 class="card-title">{{workplace.id ? "Munkahely szerkesztése" : "Munkahely hozzáadása"}}</h4>
    </div>
    <form class="form form-horizontal workplace-edit" (ngSubmit)="onSubmit(WorkplaceValidationForm.form.valid);" #WorkplaceValidationForm="ngForm">
        <div class="row">
            <div class="col-12">
                <div class="form-group row">
                    <div class="col-sm-3 col-form-label">
                        <label>{{ 'TABLE_HEADER.WORKPLACE_NAME' | translate }}</label>
                    </div>
                    <div class="col-sm-9">
                        <div class="input-group input-group-merge">
                            <div class="input-group-prepend">
                                <span class="input-group-text" [class.error]="WorkplaceValidationForm.submitted && TDWorkplacenameRef.invalid">
                                    <span [data-feather]="'briefcase'"></span>
                                </span>
                            </div>
                            <input
                                    id="workplace_name"
                                    type="text"
                                    class="form-control"
                                    name="workplace_name"
                                    [class.error]="WorkplaceValidationForm.submitted && TDWorkplacenameRef.invalid"
                                    tabindex="1"
                                    ngModel #TDWorkplacenameRef="ngModel"
                                    required
                                    placeholder="{{ 'TABLE_HEADER.WORKPLACE_NAME' | translate }}"
                                    [(ngModel)]="workplace.workplace_name"
                            />
                        </div>
                    </div>
                    <div class="col-sm-9 offset-sm-3">
                            <span *ngIf="WorkplaceValidationForm.submitted && TDWorkplacenameRef.invalid" class="invalid-form">
                                <small class="form-text text-danger">A 'Munkahely neve' kitöltése kötelező!</small>
                            </span>
                    </div>
                </div>
            </div>
            <div class="col-sm-9 offset-sm-3">
                <button type="submit" rippleEffect class="btn btn-primary mr-1">Beküld</button>
                <button type="button" rippleEffect class="btn btn-outline-secondary" (click)="activeModal.close(null)">Mégsem</button>
            </div>
        </div>
    </form>
</div>
