<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <!-- content-header component -->
        <app-content-header [contentHeader]="contentHeader"></app-content-header>
        <section id="ngx-datatable-member">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ 'MEMBER.ACTIONS' | translate }}</h4>
                </div>
                <div class="row">
                    <div class="col-12 mb-50">
                        <button (click)="openModal(null,true)" class="btn btn-primary ml-1" rippleEffect>
                            <i class="mr-0 mr-sm-1" data-feather="plus"></i
                            ><span class="d-none d-sm-inline-block">{{ 'MEMBER.ADD_NEW_RECORDS' | translate }}</span>
                        </button>
                        <a [data]="exportCSVData" class="btn btn-outline-secondary float-right mr-1" csvLink
                           rippleEffect>{{ 'MEMBER.EXPORT_CSV' | translate }}</a>
                    </div>
                    <div class="col-md-6 col-12">
                        <div class="d-flex justify-content-between align-items-center m-1">
                            <label class="d-flex align-items-center"
                            >{{ 'MEMBER.SHOW' | translate }}
                                <select [(ngModel)]="basicSelectedOption" class="form-control mx-25">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                                {{ 'MEMBER.ENTRIES' | translate }}</label
                            >
                        </div>
                    </div>
                    <div class="col-md-6 col-12 d-flex justify-content-md-end">
                        <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                            <label class="d-flex align-items-center"
                            >{{ 'MEMBER.SEARCH' | translate }}<input
                                    (keyup)="filterUpdate($event)"
                                    (search)="filterUpdate($event)"
                                    class="form-control ml-25"
                                    placeholder="{{ 'MEMBER.SEARCH_TYPE' | translate }}"
                                    type="search"
                            /></label>
                        </div>
                    </div>
                </div>
                <ngx-datatable
                        (activate)="onActivate($event)"
                        (select)="onSelect($event)"
                        [columnMode]="ColumnMode.force"
                        [footerHeight]="50"
                        [headerHeight]="40"
                        [limit]="basicSelectedOption"
                        [rowClass]="getRowClass"
                        [rowHeight]="58"
                        [rows]="kitchenSinkRows"
                        [scrollbarH]="true"
                        [selectionType]="SelectionType.checkbox"
                        class="bootstrap core-bootstrap"
                        rowHeight="auto"
                >
                    <!--   Chekbox (Selector)   -->
                    <ngx-datatable-column
                            [canAutoResize]="false"
                            [draggable]="false"
                            [resizeable]="false"
                            [sortable]="false"
                            [width]="50"
                    >
                        <ng-template
                            let-allRowsSelected="allRowsSelected"
                            let-selectFn="selectFn"
                            ngx-datatable-header-template
                        >
                            <div class="custom-control custom-checkbox">
                                <input
                                        (change)="selectFn(!allRowsSelected)"
                                        [checked]="allRowsSelected"
                                        class="custom-control-input"
                                        id="headerChkbxRef"
                                        type="checkbox"
                                />
                                <label class="custom-control-label" for="headerChkbxRef"></label>
                            </div>
                        </ng-template>
                        <ng-template
                            let-isSelected="isSelected"
                            let-onCheckboxChangeFn="onCheckboxChangeFn"
                            let-rowIndex="rowIndex"
                            ngx-datatable-cell-template
                        >
                            <div class="custom-control custom-checkbox">
                                <input
                                        (change)="onCheckboxChangeFn($event)"
                                        [checked]="isSelected"
                                        class="custom-control-input"
                                        id="rowChkbxRef{{ rowIndex }}"
                                        type="checkbox"
                                />
                                <label class="custom-control-label" for="rowChkbxRef{{ rowIndex }}"></label>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [width]="280" name="name" prop="full_name" headerClass="datatable-name-column">
                        <ng-template let-column="column" ngx-datatable-header-template>
                            {{ 'TABLE_HEADER.FULL_NAME' | translate }}
                        </ng-template>
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="d-flex align-items-center">
                                <div *ngIf="row.avatar; else customAvatar">
                                    <div class="avatar mr-1 ml-0 bg-light-primary">
                                        <img
                                            alt="avatar"
                                            class="rounded-circle mr-0"
                                            height="32"
                                            src="{{ frontendUrl + '/profile_pics/' + row.avatar }}"
                                            width="32"
                                        />
                                    </div>

                                </div>
                                <ng-template #customAvatar >
                                    <div class="avatar mr-1 ml-0 bg-light-primary">
                                        <div class="avatar-content">{{ row.lastname + ' ' + row.firstname | initials }}</div>
                                    </div>
                                </ng-template>
                                <div class="cell-line-height">
                                    <p class="font-medium-1 font-weight-bold line-height-1 mb-25">
                                        {{ row.name_prefix }} {{ row.lastname }} {{ row.firstname }}
                                    </p>
                                    <a href="mailto:{{ row.email }}"><span
                                            class="text-muted font-small-2"> {{ row.email }}</span></a>
                                </div>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column [width]="120" name="{{ 'TABLE_HEADER.STATUS' | translate }}">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div [ngClass]="{
                                        'badge-light-success': row.status == 'active',
                                        'badge-light-warning': row.status == 'inactive',
                                        'badge-light-danger': row.deleted_at != null
                                    }"
                                 class="badge badge-pill d-flex align-items-center"
                                 ngbTooltip="{{ row.deleted_at != null ? 'Ez a felhasználó TÖRÖLT, nem tud bejelentkezni a rendszerbe.' : row.status == 'active' ? 'Ez a felhasználó jelenleg AKTÍV, be tud jelentkezni a rendszerbe.' : 'Ez a felhasználó jelenleg INAKTÍV, nem tud bejelentkezni a rendszerbe.' }}"
                                 placement="top"
                            >
                                {{ row.deleted_at != null ? 'Törölt' : row.status == 'active' ? 'Aktív' : row.status == 'inactive' ? 'Inaktív' : 'Nincs státusz' }}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column [width]="140" name="{{ 'TABLE_HEADER.MEMBER_ID' | translate }}"
                                          prop="member_id"></ngx-datatable-column>
                    <ngx-datatable-column [width]="140" name="{{ 'TABLE_HEADER.PHONE' | translate }}" prop="phone">
                        <ng-template let-phone="value" ngx-datatable-cell-template>
                            <a href="tel:{{ phone }}"><span class="ng-star-inserted"
                                                            title="+36301234567">{{ phone }}</span></a>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="{{ 'TABLE_HEADER.ROLE' | translate }}" prop="role_id">
                    <ng-template let-row="row" let-role_id="value" ngx-datatable-cell-template>
                        <div class="badge badge-pill"
                             [ngClass]="{
                                        'badge-light-administrator': role_id == 1,
                                        'badge-light-editor': role_id == 2,
                                        'badge-light-member': role_id == 3
                                    }"

                        >
                            <span>{{ role_id == 1 ? 'Adminisztrátor' : role_id == 2 ? 'Szerkesztő' : 'Tag' }}</span>
                        </div>
                    </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="{{ 'TABLE_HEADER.UPDATED_AT' | translate }}"
                                          prop="updated_at"></ngx-datatable-column>

                    <ngx-datatable-column [sortable]="false" [width]="120"
                            name="{{ 'TABLE_HEADER.ACTIONS' | translate }}">
                        <ng-template
                            let-row="row"
                            ngx-datatable-cell-template>
                            <div *ngIf="row.role_id != 1">
                                <div *ngIf="row.deleted_at == null else elseBlockActions" class="d-flex align-items-center">
                                    <button (click)="openModal(row.id, false, true)" class="invisible-button"
                                            ngbTooltip="Tag megtekintése">
                                        <i class="text-primary cursor-pointer datatable-action-icons"
                                           data-feather="eye"></i>
                                    </button>
                                    <button (click)="openModal(row.id, false)" class="invisible-button"
                                            ngbTooltip="Tag szerkesztése">
                                        <i class="text-primary cursor-pointer datatable-action-icons"
                                           data-feather="edit"></i>
                                    </button>
                                    <button (click)="deleteData(row.id)" class="invisible-button"
                                            ngbTooltip="Tag törlése">
                                        <i class="text-primary cursor-pointer datatable-action-icons"
                                           data-feather="x-octagon"></i>
                                    </button>
                                </div>
                                <ng-template #elseBlockActions ngx-datatable-cell-template>
                                    <button (click)="openModal(row.id, false, true)" class="invisible-button"
                                            ngbTooltip="Ez a felhasználó törölt! Megtekintés.">
                                        <i class="text-primary cursor-pointer datatable-action-icons"
                                           data-feather="eye"></i>
                                    </button>
                                </ng-template>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </div>
        </section>
    </div>
</div>
