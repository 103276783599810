export const locale = {
    lang: 'en',
    data: {
        MEMBER: {
            ACTIONS: 'Actions',
            ADD_NEW_RECORDS: 'Új tag',
            EXPORT_CSV: 'Export CSV',
            SHOW: 'Show',
            ENTRIES: 'entries',
            SEARCH: 'Search',
            SEARCH_TYPE: 'Name or Member Id',
        },
        TABLE_HEADER: {
            ID: 'Id',
            MEMBER_ID: 'Member id',
            STATUS: 'Status',
            FULL_NAME: 'Name',
            NAME_PREFIX: 'Prefix',
            LASTNAME: 'Lastname',
            FIRSTNAME: 'Firstname',
            EMAIL: 'E-mail',
            PHONE: 'Phone',
            CREATED_AT: 'Created at',
            UPDATED_AT: 'Updated at',
            DELETED_AT: 'Deleted at',
            ACTIONS: 'Actions',
            PERSONAL_INFORMATIONS: 'Personal informations',
            ROLE: 'Role',
            DIVISIONS: 'Divisions',
            SECTORS: 'Sectors',
            WORKPLACES: 'Workplaces',
            USER_ADDRESSES: 'The Member addresses',
            USER_DIVISIONS: 'The Member divisions',
            USER_SECTORS: 'The Member sectors',
            USER_WORKPLACES: 'The Member workplaces',
        }
    }
};
