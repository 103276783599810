<div class="card">
    <div class="card-header">
        <h4 class="card-title">{{sector.id ? "Ágazat szerkesztése" : "Ágazat hozzáadása"}}</h4>
    </div>
    <form class="form form-horizontal sector-edit" (ngSubmit)="onSubmit(SectorValidationForm.form.valid);" #SectorValidationForm="ngForm">
        <div class="row">
            <div class="col-12">
                <div class="form-group row">
                    <div class="col-sm-3 col-form-label">
                        <label>{{ 'TABLE_HEADER.SECTOR_NAME' | translate }}</label>
                    </div>
                    <div class="col-sm-9">
                        <div class="input-group input-group-merge">
                            <div class="input-group-prepend">
                                <span class="input-group-text" [class.error]="SectorValidationForm.submitted && TDSectornameRef.invalid">
                                    <span [data-feather]="'briefcase'"></span>
                                </span>
                            </div>
                            <input
                                    id="sector_name"
                                    type="text"
                                    class="form-control"
                                    name="sector_name"
                                    [class.error]="SectorValidationForm.submitted && TDSectornameRef.invalid"
                                    tabindex="1"
                                    ngModel #TDSectornameRef="ngModel"
                                    required
                                    placeholder="{{ 'TABLE_HEADER.SECTOR_NAME' | translate }}"
                                    [(ngModel)]="sector.sector_name"
                            />
                        </div>
                    </div>
                    <div class="col-sm-9 offset-sm-3">
                            <span *ngIf="SectorValidationForm.submitted && TDSectornameRef.invalid" class="invalid-form">
                                <small class="form-text text-danger">Az 'Ágazat neve' kitöltése kötelező!</small>
                            </span>
                    </div>
                </div>
            </div>
            <div class="col-sm-9 offset-sm-3">
                <button type="submit" rippleEffect class="btn btn-primary mr-1">Beküld</button>
                <button type="button" rippleEffect class="btn btn-outline-secondary" (click)="activeModal.close(null)">Mégsem</button>
            </div>
        </div>
    </form>
</div>
