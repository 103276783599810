<div class="auth-wrapper auth-v1 px-2">
  <div class="auth-inner py-2">
    <!-- Forgot Password v1 -->
    <div class="card mb-0">
      <div class="card-body">
        <div class="row d-flex align-items-center justify-content-center mb-2">
          <!--          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="28" />-->
          <img src="assets/images/logo/crm-logo.png" alt="logo" height="64" />
          <h2 class="brand-text text-primary ml-1">{{ coreConfig.app.appName }}</h2>
        </div>

        <h4 class="card-title mb-1">Elfelejtette jelszavát? 🔔</h4>
        <p class="card-text mb-2">Kérem, adja meg az E-mail címét, és elküldjük Önnek az utasításokat a jelszó visszaállításához.</p>

        <form class="auth-forgot-password-form mt-2" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="forgot-password-email" class="form-label">E-mail</label>
            <input
              id="forgot-password-email"
              type="text"
              class="form-control"
              placeholder="mail@mail.hu"
              formControlName="email"
              aria-describedby="forgot-password-email"
              tabindex="1"
              autofocus
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
            />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">E-mail cím kitöltése kötelező</div>
              <div *ngIf="f.email.errors.email">Érvénytelen E-mail cím</div>
            </div>
          </div>
          <button [disabled]="submitted" (click)="clicked = true;" class="btn btn-primary btn-block" tabindex="2" rippleEffect>Jelszóemlékeztető küldése</button>
        </form>

        <p class="text-center mt-2">
          <a routerLink="/pages/authentication/login">
            <i data-feather="chevron-left" class="mb-25"></i> Vissza a bejelentkezéshez
          </a>
        </p>
      </div>
    </div>
    <!-- /Forgot Password v1 -->
  </div>
</div>
