<div class="auth-wrapper auth-v2">
    <div class="auth-inner row m-0">
        <!-- Brand logo--><a class="brand-logo" href="/faq">
        <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="28" />
        <h2 class="brand-text text-primary ml-1">{{ coreConfig.app.appName }}</h2></a
    >
        <!-- /Brand logo-->
        <!-- Left Text-->
        <div class="d-none d-lg-flex col-lg-8 align-items-center p-5">
            <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                <img
                        class="img-fluid"
                        [src]="
            coreConfig.layout.skin === 'dark'
              ? 'assets/images/pages/register-v2-dark.svg'
              : 'assets/images/pages/register-v2.svg'
          "
                        alt="NESZE CRM"
                />
            </div>
        </div>
    </div>
</div>
