import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbCalendar, NgbDate, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {User} from "../../../auth/models";
import {MemberService} from "../member.service";
import {DivisionService} from "../../division/division.service";
import {ActivatedRoute} from "@angular/router";

import {CustomToastrComponent} from 'app/main/extensions/toastr/custom-toastr/custom-toastr.component';
import {cloneDeep} from 'lodash';
import {ToastrService, GlobalConfig} from 'ngx-toastr';
import {environment} from "../../../../environments/environment";
import {SectorService} from "../../sector/sector.service";
import {WorkplaceService} from "../../workplace/workplace.service";

@Component({
	selector: 'app-member-edit.edit-modal',
	templateUrl: './member-edit-modal-form.component.html',
	styleUrls: ['./member-edit-modal-form.component.scss']
})

export class MemberEditModalFormComponent implements OnInit {
	@Input() public id: number;
	@Input() public isAddNew: boolean;
	@Input() public viewOnly: boolean;

	private data: any;
	private result: any;
	private readonly options: GlobalConfig;
	public member = new User();
	public divisiondata: Array<object> = [];
	public sectordata: Array<object> = [];
	public workplacedata: Array<object> = [];
	public checkedDivisions=[];
	public checkedSectors=[];
	public checkedWorkplaces=[];
	public frontendUrl: string = `${environment.frontendUrl}`;
	public deletionIsDisabled: boolean = true;

	/*  ***** Custom calendar  ***** */
	public BirthDateDPdata: NgbDateStruct;
	isWeekend = (date: NgbDate) => this.calendar.getWeekday(date) >= 6;
	isDisabled = (date: NgbDate, current: { month: number; year: number }) =>
		date.month !== current.month;
	//public today = this.calendar.getToday();

	constructor(
		private route: ActivatedRoute,
		private memberService: MemberService,
		private divisionService: DivisionService,
		private sectorService: SectorService,
		private workplaceService: WorkplaceService,
		private calendar: NgbCalendar,
		private toastr: ToastrService,
		public activeModal: NgbActiveModal,
	) {
		this.options = this.toastr.toastrConfig;
	}

	ngOnInit() {
		if (!this.isAddNew) {
			this.getMember();
		}
		this.getDivisions();
		this.getSectors();
		this.getWorkplaces();
	}

	onSubmit(isValid) {
		// stop here if form is invalid
		if (!isValid) {
			return;
		}

		if (this.member && this.member.id) {
			// set the member birth date from the date picker
			this.member.birth_date = this.parseDateStructtoDateStr(this.BirthDateDPdata);

			// *** Update *** //
			this.memberService.update(this.id, this.member)
				.subscribe(
					() => {
						this.result = {status: true, crudType: 'u'};
						this.activeModal.close(this.result);
					},
					(res) => {
						this.toastrCustomDanger('Hiba', res.message)
					}
				);
		}
		else {
			// *** Create *** //
			this.memberService.create(this.member)
				.subscribe(
					() => {
						this.result = {status: true, crudType: 'c'};
						this.activeModal.close(this.result);
					},
					(res) => {
						this.toastrCustomDanger('Hiba', res.message)
					}
				);
		}
	}

	// Visszaadja a Tagot
	private getMember() {
		this.memberService.getMemberById(this.id).subscribe(
			res => {
				this.data = res;
				if (this.data) {
					this.member = this.data;
					//console.log(this.member);
					if (this.data.birth_date) {
						let parsedDate = this.parseDateStrtoDateStruct(this.data.birth_date);
						this.BirthDateDPdata = new NgbDate(parsedDate.year, parsedDate.month, parsedDate.day);
					}

					// Ha vannak tagozatai a usernek
					if (this.data.divisions) {
						this.data.divisions.forEach(division => {
							this.checkedDivisions.push(division.id);
						});
						this.member.divisions = this.checkedDivisions;
					}

					// Ha vannak ágazatai a usernek
					if (this.data.sectors) {
						this.data.sectors.forEach(sector => {
							this.checkedSectors.push(sector.id);
						});
						this.member.sectors = this.checkedSectors;
					}

					// Ha vannak munkahelyei a usernek
					if (this.data.workplaces) {
						this.data.workplaces.forEach(workplace => {
							this.checkedWorkplaces.push(workplace.id);
						});
						this.member.workplaces = this.checkedWorkplaces;
					}
				}
			});
	}

	// Visszaadja az összes Tagozatot
	private getDivisions() {
		this.divisionService.getDivisions().subscribe(
				divisions => {
					divisions.forEach(division => {
						this.divisiondata.push(division);
					});
		});
	}

	// Visszaadja az összes Ágazatot
	private getSectors() {
		this.sectorService.getSectors().subscribe(
			sectors => {
				sectors.forEach(sector => {
					this.sectordata.push(sector);
				});
			});
	}

	// Visszaadja az összes Munkahelyet
	private getWorkplaces() {
		this.workplaceService.getWorkplaces().subscribe(
			workplaces => {
				workplaces.forEach(workplace => {
					this.workplacedata.push(workplace);
				});
			});
	}

	// Profilkép törlése
	deleteProfilepic(avatar_id) {
		if (confirm('Biztos, hogy törli a profilképet?')) {
			this.memberService.destroy_profilepic(avatar_id)
				.subscribe(
					(res) => {
						alert('A profilkép törölve!');
						this.member.avatar = '';
						this.member.avatar_id = null;
						this.result = {status: true, crudType: 'u'};
						this.activeModal.close(this.result);
					},
					(err) => {
						alert('Hiba történt az adatok feldolgozása során!');
					}
				);
		}
	}

	setDeletionReason() {
		this.memberService.set_deletion_reason(this.id, this.member)
			.subscribe(
				() => {
					this.result = {status: true, crudType: 'u'};
					this.activeModal.close(this.result);
				},
				(res) => {
					this.toastrCustomDanger('Hiba', res.message)
				}
			);
	}

	// Törlési indok módosítása
	toggleDisable() {
		this.deletionIsDisabled = false;
	}

	// Változik valamelyik Divizió Checkbox
	onCheckChangeDivision(value, event) {
		const checked = event.target.checked;
		if (checked) {
			/* Selected */
			this.checkedDivisions.push(value);
		}
		else {
			/* UnSelected */
			const index = this.checkedDivisions.indexOf(value);
			this.checkedDivisions.splice(index, 1);
		}
		this.member.divisions = this.checkedDivisions;
	}

	// Változik valamelyik Divizió Checkbox
	onCheckChangeSector(value, event) {
		const checked = event.target.checked;
		if (checked) {
			/* Selected */
			this.checkedSectors.push(value);
		}
		else {
			/* UnSelected */
			const index = this.checkedSectors.indexOf(value);
			this.checkedSectors.splice(index, 1);
		}
		this.member.sectors = this.checkedSectors;
	}

	// Változik valamelyik Divizió Checkbox
	onCheckChangeWorkplace(value, event) {
		const checked = event.target.checked;
		if (checked) {
			/* Selected */
			this.checkedWorkplaces.push(value);
		}
		else {
			/* UnSelected */
			const index = this.checkedWorkplaces.indexOf(value);
			this.checkedWorkplaces.splice(index, 1);
		}
		this.member.workplaces = this.checkedWorkplaces;
	}

	// A Tag Divizióinak a beállítása (checked v. nem)
	isCheckedDivision(division_id) {
		return this.checkedDivisions.includes(division_id);
	}

	// A Tag Ágazatainak a beállítása (checked v. nem)
	isCheckedSector(sector_id) {
		return this.checkedSectors.includes(sector_id);
	}

	// A Tag Munkahelyeinek a beállítása (checked v. nem)
	isCheckedWorkplace(workplace_id) {
		return this.checkedWorkplaces.includes(workplace_id);
	}

	parseDateStrtoDateStruct(value: string | null): NgbDateStruct | null {
		if (value) {
			const date = value.split('-');
			return {
				year: parseInt(date[0], 10),
				month: parseInt(date[1], 10),
				day: parseInt(date[2], 10),
			};
		}
		return null;
	}

	parseDateStructtoDateStr(date: NgbDateStruct | null): string | null {
		return date ? date.year + '-' + date.month + '-' + date.day : null;
	}

	// **************************** Toastr Service ****************************
	// Init
	toastrInit() {
		const customToastrRef = cloneDeep(this.options);
		customToastrRef.timeOut = 10000;
		customToastrRef.toastComponent = CustomToastrComponent;
		customToastrRef.closeButton = true;
		customToastrRef.tapToDismiss = false;
		customToastrRef.progressBar = true;
		customToastrRef.toastClass = 'toast ngx-toastr';
		return customToastrRef;
	}

	// Error
	toastrCustomDanger(title, message) {
		const customToastrRef = this.toastrInit();
		this.toastr.error(message, title, customToastrRef);
	}
}
