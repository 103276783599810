import {Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FlatpickrOptions} from 'ng2-flatpickr';

import {CoreTranslationService} from '@core/services/translation.service';
import {locale as english} from 'app/main/account-settings/i18n/en';
import {locale as hungarian} from 'app/main/account-settings/i18n/hu';

import {AccountSettingsService} from 'app/main/account-settings/account-settings.service';
import {NgbCalendar, NgbDate, NgbDateStruct, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {User} from "../../auth/models";
import {GlobalConfig, ToastrService} from "ngx-toastr";
import {CustomToastrComponent} from "../extensions/toastr/custom-toastr/custom-toastr.component";
import {cloneDeep} from 'lodash';
import {FormControl, FormGroup} from "@angular/forms";
import {environment} from "../../../environments/environment";
import {ColumnMode, DatatableComponent, SelectionType} from '@swimlane/ngx-datatable';
import {AddressEditModalFormComponent} from "../address/modal-form/address-edit-modal-form.component";
import {AuthenticationService} from "../../auth/service";

@Component({
	selector: 'app-account-settings',
	templateUrl: './account-settings.component.html',
	styleUrls: ['./account-settings.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AccountSettingsComponent implements OnInit, OnDestroy {
	// *** private
	private readonly options: GlobalConfig;

	// *** public
	public contentHeader: object;
	//public data: any;
	public birthDateOptions: FlatpickrOptions = {
		altInput: true
	};
	public passwordTextTypeOld = false;
	public passwordTextTypeNew = false;
	public passwordTextTypeRetype = false;
	public avatarImage: string;
	public accountData = new User();
	public old_password: string;
	public new_password: string;
	public new_password_again: string;
	public code: number;
	public errorMessage: string;

	/*  ***** Address  ***** */
	// *** private
	private tempData = [];

	public rows: any;
	public selected = [];
	public kitchenSinkRows: any;
	public basicSelectedOption: number = 10;
	public ColumnMode = ColumnMode;
	public SelectionType = SelectionType;

	@ViewChild(DatatableComponent) table: DatatableComponent;
	@ViewChild('tableRowDetails') tableRowDetails: any;

	/*  ***** Custom calendar  ***** */
	public BirthDateDPdata: NgbDateStruct;
	isWeekend = (date: NgbDate) => this.calendar.getWeekday(date) >= 6;
	isDisabled = (date: NgbDate, current: { month: number; year: number }) => date.month !== current.month;
	//public today = this.calendar.getToday();

	/*  ***** Profile Pic uploader  ***** */
	fileForm = new FormGroup({
		profilePic: new FormControl(''),
		fileSource: new FormControl('')
	});

	get ff(){
		return this.fileForm.controls;
	}

	// private
	private _unsubscribeAll: Subject<any>;
	private result: any;

	/**
	 * Constructor
	 *
	 * @param {AccountSettingsService} _accountSettingsService
	 * @param _coreTranslationService
	 * @param _authenticationService
	 * @param calendar
	 * @param toastr
	 * @param modalService
	 */
	constructor(
		private _accountSettingsService: AccountSettingsService,
		private _coreTranslationService: CoreTranslationService,
		private _authenticationService: AuthenticationService,
		private calendar: NgbCalendar,
		private toastr: ToastrService,
		public modalService: NgbModal
	) {
		this._unsubscribeAll = new Subject();
		this.options = this.toastr.toastrConfig;
		this._coreTranslationService.translate(english, hungarian);
	}

	// Public Methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Toggle Password Text Type Old
	 */
	togglePasswordTextTypeOld() {
		this.passwordTextTypeOld = !this.passwordTextTypeOld;
	}

	/**
	 * Toggle Password Text Type New
	 */
	togglePasswordTextTypeNew() {
		this.passwordTextTypeNew = !this.passwordTextTypeNew;
	}

	/**
	 * Toggle Password Text Type Retype
	 */
	togglePasswordTextTypeRetype() {
		this.passwordTextTypeRetype = !this.passwordTextTypeRetype;
	}

	/**
	 * Upload Image
	 *
	 * @param event
	 */
	uploadImage(event: any) {
		if (event.target.files && event.target.files[0]) {
			let reader = new FileReader();
			reader.onload = (event: any) => {
				this.avatarImage = event.target.result;
			};
			reader.readAsDataURL(event.target.files[0]);
			const file = event.target.files[0];
			this.fileForm.patchValue({
				fileSource: file
			});
		}
	}

	resetProfilePic() {
		this.avatarImage = null;
		this.fileForm.patchValue({
			fileSource: null
		});
	}

	deleteProfilePic() {
		if (confirm('Biztos, hogy törli a profilképet?')) {
			this._accountSettingsService.deleteprofilepic(this.accountData.id)
				.subscribe(
					(res) => {
						if (res.status === 204)
							this.toastrCustomSuccess('Sikeres törlés!', 'A profilkép törölve! A módosítások a következő belépéskor lépnek életbe.');
					},
					(err) => {
						this.errorMessage = err.message;
						this.code = err.code;
						this.errorMessage = 'Hiba történt az adatok feldolgozása során!';
						this.toastrCustomDanger('Hiba', this.errorMessage)
					}
				);
		}
	}

	changeProfilePic() {
		if (this.fileForm.get('fileSource').value) {
			const formData = new FormData();
			formData.append('file', this.fileForm.get('fileSource')?.value);
			this._accountSettingsService.changeprofilepic(this.accountData.id, formData)
				.subscribe(
					(res) => {
						if (res.status === 201)
							this.toastrCustomSuccess('Sikeres módosítás!', 'A profilkép módosult! A módosítások a következő belépéskor lépnek életbe.');
					},
					(err) => {
						this.errorMessage = err.message;
						this.code = err.code;
						if (this.code === 4001)
							this.errorMessage = 'Nincs fájl megadva!';
						else if (this.code === 4002)
							this.errorMessage = 'Validációs hiba! Megengedett JPG, PNG. Maximális méret 800kB.';
						else if (this.code === 4003)
							this.errorMessage = 'Fájl feltöltés hiba! Kérem, próblája meg újra később!';
						else if (this.code === 4004)
							this.errorMessage = 'Adatbázis hiba! Kérem, próblája meg újra később!';
						else if (this.code === 4005)
							this.errorMessage = 'Fájl törlés hiba! A régi profilkép törlése sikertelen volt!';
						else
							this.errorMessage = 'Hiba történt az adatok feldolgozása során!';

						this.toastrCustomDanger('Hiba', this.errorMessage)
					}
				);
		}
		else
			this.toastrCustomWarning('Hiányzó fájl', 'Kérem, válasszon ki egy fájlt a feltöltéshez!');
	}

	// Lifecycle Hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit() {
		this.getAccountData();

		// content header
		this.contentHeader = {
			headerTitle: 'Saját adatok',
			actionButton: true,
			breadcrumb: {
				type: '',
				links: [
					{
						name: 'Kezdőlap',
						isLink: true,
						link: '/'
					},
					{
						name: 'Saját adatok',
						isLink: false
					}
				]
			}
		};
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}


	/**
	 * On PersonalData Form Submit
	 */
	onSubmitPersonalData(isValid: boolean) {
		// stop here if form is invalid
		if (!isValid) {
			return;
		}

		if (this.accountData && this.accountData.id) {
			// set the member birth date from the date picker
			this.accountData.birth_date = this.parseDateStructtoDateStr(this.BirthDateDPdata);
			// *** Update *** //
			this._accountSettingsService.update(this.accountData.id, this.accountData)
				.subscribe(
					(res) => {
						if (res.status === 200)
							this.toastrCustomSuccess('Sikeres módosítás!', 'A személyes adatok módosultak!');

						if (res.body.status === 'inactive') {
							this.toastrCustomInfo('Sikeres E-mail cím változtatás!', 'Új E-mail-címedet az általunk küldött E-mailben található linken tudod aktiválni.');
							this._authenticationService.logout();
						}
					},
					(res) => {
						this.toastrCustomDanger('Hiba', res.message)
					}
				);
		}
		else
			this.toastrCustomDanger('Hiba', 'Érvénytelen azonosító!');
	}


	/**
	 * On SubmitPasswordChange Form Submit
	 */
	onSubmitPasswordChange(isValid: boolean) {
		// stop here if form is invalid
		if (!isValid) {
			return;
		}

		if (this.accountData && this.accountData.id) {
			// *** Password Change *** //
			const data = {
				old_password: this.old_password,
				new_password: this.new_password,
				new_password_again: this.new_password_again
			};
			this._accountSettingsService.changepasswd(data)
				.subscribe(
					(res) => {
						if (res.status === 200)
							this.toastrCustomSuccess('Sikeres módosítás!', 'A jelszó módosult!');
					},
					(res) => {
						if (res.status === 400)
							this.toastrCustomWarning('Hiba', 'Az Új jelszó és az Új jelszó mégegyszer mezők nem egyeznek meg!');
						else if (res.status === 401)
							this.toastrCustomWarning('Hiba', 'A Régi jelszó nem megfelelő!');
						else if (res.status === 403)
							this.toastrCustomDanger('Hiba', 'Nincs jogosultsága a kéréshez!');
						else if (res.status === 406)
							this.toastrCustomWarning('Hiba', 'Az Új jelszó nem egyezhet meg a régivel!');
						else
							this.toastrCustomDanger('Hiba', res.message);
					}
				);
		}
		else
			this.toastrCustomDanger('Hiba', 'Érvénytelen azonosító!');
	}

	private getAccountData() {
		this._accountSettingsService.onSettingsChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(
			res => {
				this.accountData = res;
				if (this.accountData) {
					// Date parse
					if (this.accountData.birth_date) {
						let parsedDate = this.parseDateStrtoDateStruct(this.accountData.birth_date);
						this.BirthDateDPdata = new NgbDate(parsedDate.year, parsedDate.month, parsedDate.day);
					}
					// Avatar
					if (this.accountData.avatar)
						this.avatarImage = `${environment.frontendUrl}/profile_pics/` + this.accountData.avatar;
					else
						this.avatarImage = '../../../../assets/images/portrait/small/avatar-s-default.jpg';

					// Address
					this.rows = this.accountData.addresses;
					this.tempData = this.rows;
					this.kitchenSinkRows = this.rows;
				}
			})
	}

	parseDateStrtoDateStruct(value: string | null): NgbDateStruct | null {
		if (value) {
			const date = value.split('-');
			return {
				year: parseInt(date[0], 10),
				month: parseInt(date[1], 10),
				day: parseInt(date[2], 10),
			};
		}
		return null;
	}

	parseDateStructtoDateStr(date: NgbDateStruct | null): string | null {
		return date ? date.year + '-' + date.month + '-' + date.day : null;
	}

	// Public Methods
	// -----------------------------------------------------------------------------------------------------
	// **************************** Address Methods ****************************
	/**
	 * For ref only, log selected values
	 *
	 * @param selected
	 */
	onSelect({selected}) {
		//console.log('Select Event', selected, this.selected);
		this.selected.splice(0, this.selected.length);
		this.selected.push(...selected);
	}

	/**
	 * For ref only, log activate events
	 *
	 * @param event
	 */
	onActivate(event) {
		//console.log('Activate Event', event);
	}

	/**
	 * Search (filter)
	 *
	 * @param event
	 */
	filterUpdate(event) {
		const val = event.target.value.toLowerCase();
		// filter our data
		// update the rows
		this.kitchenSinkRows = this.tempData.filter(function (d) {
			return d.city.toLowerCase().indexOf(val) !== -1
				|| d.zip_code.toString().toLowerCase().indexOf(val) !== -1
				|| !val;
		});

		// Whenever the filter changes, always go back to the first page
		this.table.offset = 0;
	}

	// **************************** Popup for create and update **************************** //
	openModal(id: number, isAddNew: boolean, viewOnly: boolean = false, isAdmin: boolean = false, userId: number = -1) {
		// Open modal form component
		const modalRef = this.modalService.open(AddressEditModalFormComponent, {centered: true, size: "lg"});
		modalRef.componentInstance.id = id;
		modalRef.componentInstance.isAddNew = isAddNew;
		modalRef.componentInstance.viewOnly = viewOnly;
		modalRef.componentInstance.isAdmin = isAdmin;
		modalRef.componentInstance.userId = userId;

		// handle result passing back from modal form component
		modalRef.result
			.then((result) => {
				if (result) {
					if (result.status === true) {
						// Datatable Refresh
						this._accountSettingsService.getDataTableRows().then($res => {
							this.rows = $res['addresses'];
						});
						// Toaster
						if (result.crudType == 'c') {
							let title = 'Sikeres létrehozás!';
							let message = 'A cím létrejött.';
							this.toastrCustomSuccess(title, message);
						}
						else if (result.crudType == 'u') {
							let title = 'Sikeres módosítás!';
							let message = 'A cím módosult.';
							this.toastrCustomSuccess(title, message);
						}
						else if (result.crudType == '') {
							let title = 'Információ!';
							let message = 'Nem történt módosítás.';
							this.toastrCustomInfo(title, message);
						}
					}
					else {
						let title = 'Hiba!';
						let message = result.message;
						this.toastrCustomDanger(title, message);
					}
				}
				else {
					// user click cancel
					let title = 'Információ!';
					let message = 'Nem történt módosítás.';
					this.toastrCustomInfo(title, message);
				}
			})
			.catch(() => {
				// user click outside of the modal form
				let title = 'Információ!';
				let message = 'Nem történt módosítás.';
				this.toastrCustomInfo(title, message);
			});
	}

	// Delete
	deleteData(id) {
		if (confirm("Biztos, hogy törli?")) {
			this._accountSettingsService.deleteaddress(id).subscribe(() => {
					// Datatable Refresh
					this._accountSettingsService.getDataTableRows().then($res => {
						this.rows = $res;
					});
					let title = 'Sikeres törlés!';
					let message = 'A cím törölve.';
					this.toastrCustomSuccess(title, message);
				},
				(res) => {
					let title = 'Hiba!';
					let message = res.message;
					this.toastrCustomDanger(title, message);
				}
			);
		}
	}

	// **************************** Datatable Customizations ****************************
	getRowClass(row) {
		return {
			'address_is_deleted': row.deleted_at !== null
		};
	}

	// getCellClass({row, column, value}): any {
	// 	return {
	// 		'address_is_deleted_cell': column.deleted_at !== null
	// 	};
	// }

	// **************************** Toastr Methods ****************************
	// Init
	toastrInit() {
		const customToastrRef = cloneDeep(this.options);
		customToastrRef.timeOut = 10000;
		customToastrRef.toastComponent = CustomToastrComponent;
		customToastrRef.closeButton = true;
		customToastrRef.tapToDismiss = false;
		customToastrRef.progressBar = true;
		customToastrRef.toastClass = 'toast ngx-toastr';
		return customToastrRef;
	}

	// Success
	toastrCustomSuccess(title, message) {
		const customToastrRef = this.toastrInit();
		this.toastr.success(message, title, customToastrRef);
	}

	// Warning
	toastrCustomWarning(title, message) {
		const customToastrRef = this.toastrInit();
		this.toastr.warning(message, title, customToastrRef);
	}

	// Error
	toastrCustomDanger(title, message) {
		const customToastrRef = this.toastrInit();
		this.toastr.error(message, title, customToastrRef);
	}

	// Info
	toastrCustomInfo(title, message) {
		const customToastrRef = this.toastrInit();
		this.toastr.info(message, title, customToastrRef);
	}
}
