import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CoreConfigService } from '@core/services/config.service';
import {AuthenticationService} from "../../../../auth/service";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
	selector: 'app-verify-email',
	templateUrl: './verify-email.component.html',
	styleUrls: ['./verify-email.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class VerifyEmailComponent implements OnInit {
	// Public
	public coreConfig: any;
	public submitted = false;
	public errorMessage = '';
	public code;

	// Private
	private _unsubscribeAll: Subject<any>;

	/**
	 * Constructor
	 *
	 * @param {CoreConfigService} _coreConfigService
	 * @param _authService
	 * @param _toastrService
	 * @param _router
	 * @param _route
	 */
	constructor(
		private _coreConfigService: CoreConfigService,
		private _authService: AuthenticationService,
		private _toastrService: ToastrService,
		private _router: Router,
		private _route: ActivatedRoute) {
		this._unsubscribeAll = new Subject();

		// Configure the layout
		this._coreConfigService.config = {
			layout: {
				navbar: {
					hidden: true
				},
				menu: {
					hidden: true
				},
				footer: {
					hidden: true
				},
				customizer: false,
				enableLocalStorage: false
			}
		};
	}

	// Lifecycle Hooks
	// -----------------------------------------------------------------------------------------------------
	/**
	 * On init
	 */
	ngOnInit(): void {
		// Subscribe to authservice email verification
		this._authService.verify_email(
			this._route.snapshot.queryParamMap.get('user_id'),
			this._route.snapshot.queryParamMap.get('link')).subscribe(
			() => {
				this._router.navigateByUrl('/login').then(() => setTimeout(() => {
					this._toastrService.success(
						'Sikeresen megerősítetted az E-mail címedet. Mostmár beléphetsz a CRM rendszerbe!',
						'Sikeres megerősítés',
						{ toastClass: 'toast ngx-toastr', closeButton: true, disableTimeOut: true}
					);
				}, 200));
			},
			err => {
				this.errorMessage = err.message;
				this.code = err.code;
				if (this.code === 4001)
					this.errorMessage = 'Érvénytelen megerősítőkód!';
				else if (this.code === 4002)
					this.errorMessage = 'Ez a megerősítőkód már lejárt!';
				else if (this.code === 4003)
					this.errorMessage = 'Ezt a megerősítő kódot már felhasználták!';
				else
					this.errorMessage = 'Hiba történt a megerősítő kód feldolgozása során!';

				setTimeout(() => {
					this._toastrService.error(
						this.errorMessage + ' Kérjük, vedd fel a kapcsolatot az ügyfélszolgálattal: info@nesze.org',
						'Hiba történt a megerősítéskor',
						{ toastClass: 'toast ngx-toastr', closeButton: true, disableTimeOut: true}
					);
				}, 200);
			}
		);

		// Subscribe to config changes
		this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
			this.coreConfig = config;
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
