<div class="auth-wrapper auth-v2">
  <div class="auth-inner row m-0">
    <!-- Brand logo-->
<!--    <a class="brand-logo" href="https://nesze.org/site/letoltheto-dokumentumok/">-->
<!--<img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="28" />-->

<!--      <h2 class="brand-text text-primary ml-1">{{ coreConfig.app.appName }}</h2>-->
<!--  </a>-->
    <!-- /Brand logo-->
    <!-- Left Text-->
    <div class="col-lg-8 p-5 secondary-light-bg">
      <div class="row d-flex align-items-center">
        <img src="assets/images/logo/crm-logo.png" alt="logo" height="128" />
        <h2 class="card-title font-weight-bold mb-1 ml-2 reg-title">Üdvözöljük ügyfélportálunkon</h2>
      </div>
      <div class="row">
        <p class="card-text mt-2 mb-3">
          Mi vagyunk az a szakszervezet, amelyik kiáll a dolgozók jogaiért és érdekeiért. Segítünk megoldani a munkahelyi problémákat, és számos előnnyel látjuk el a tagjainkat.
        </p>
        <p class="card-text mb-3">
        Ha szeretnél tagja lenni a Nemzeti Szakszervezetnek, akkor nincs más dolgod, mint kitölteni az alábbi űrlapot. Miután elküldted nekünk az adataidat, hamarosan felvesszük veled a kapcsolatot és elmondjuk, hogyan tudsz taggá válni.
        </p>
      </div>
      <div class="row">
        <img
          class="img-fluid register-image"
          [src]="
            coreConfig.layout.skin === 'dark'
              ? 'assets/images/pages/crm-register.jpg'
              : 'assets/images/pages/crm-register.jpg'
          "
          alt="Regisztráció"
        />
      </div>
    </div>
    <!-- /Left Text-->
    <!-- Register-->
    <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
      <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
        <h2 class="card-title font-weight-bold mb-1">Regisztráció 📝</h2>
        <p class="card-text mb-2">A sikeres regisztrációhoz kérem, adja meg az adatait!</p>
        <form class="auth-register-form mt-2" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="email" class="form-label">E-mail</label>
            <div class="input-group input-group-merge">
              <div class="input-group-prepend">
                  <span class="input-group-text" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                    <span [data-feather]="'mail'"></span>
                  </span>
              </div>
              <input
                      id="email"
                      type="text"
                      formControlName="email"
                      class="form-control"
                      placeholder="E-mail"
                      aria-describedby="email"
                      tabindex="1"
                      [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
              />
            </div>
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Az E-mail cím kitöltése kötelező</div>
              <div *ngIf="f.email.errors.email">Érvénytelen E-mail cím</div>
            </div>
          </div>


          <div class="form-group">
            <label for="register-password" class="form-label">Jelszó</label>
            <div class="input-group input-group-merge form-password-toggle">
              <div class="input-group-prepend">
                  <span class="input-group-text" [ngClass]="{ 'is-invalid error': submitted && f.password.errors }">
                    <span [data-feather]="'lock'"></span>
                  </span>
              </div>
              <input
                      id="register-password"
                      [type]="passwordTextType ? 'text' : 'password'"
                      formControlName="password"
                      class="form-control form-control-merge"
                      placeholder="············"
                      aria-describedby="password"
                      tabindex="2"
                      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                      [ngClass]="{ 'is-invalid error': submitted && f.password.errors }"
              />
              <div class="input-group-append">
                <span class="input-group-text cursor-pointer"
                ><i
                        class="feather font-small-4"
                        [ngClass]="{
                      'icon-eye-off': passwordTextType,
                      'icon-eye': !passwordTextType
                    }"
                        (click)="togglePasswordTextType()"
                ></i
                ></span>
              </div>
            </div>
            <div
                    *ngIf="submitted && f.password.errors"
                    class="invalid-feedback"
                    [ngClass]="{ 'd-block': submitted && f.password.errors }"
            >
              <div *ngIf="f.password.errors.required">A Jelszó kitöltése kötelező</div>
              <div *ngIf="f.password.errors.pattern">A Jelszónak tartalmaznia kell legalább egy számot, egy nagybetűt egy kisbetűt, és legalább 8 vagy több karaktert.</div>
            </div>
          </div>

          <div class="form-group">
            <label for="name_prefix" class="form-label">Előtag</label>
            <div class="input-group input-group-merge">
              <div class="input-group-prepend">
                <span class="input-group-text"><span [data-feather]="'user'"></span></span>
              </div>
              <input
                      id="name_prefix"
                      formControlName="name_prefix"
                      type="text"
                      class="form-control"
                      name="name_prefix"
                      tabindex="3"
                      placeholder="Előtag"
                      aria-describedby="name_prefix"
              />
            </div>
          </div>

          <div class="form-group">
            <label for="lastname" class="form-label">Vezetéknév</label>
            <div class="input-group input-group-merge">
              <div class="input-group-prepend">
                  <span class="input-group-text" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }">
                    <span [data-feather]="'user'"></span>
                  </span>
              </div>
              <input
                      id="lastname"
                      type="text"
                      formControlName="lastname"
                      class="form-control"
                      placeholder="Vezetéknév"
                      aria-describedby="lastname"
                      tabindex="4"
                      [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }"
              />
            </div>
            <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
              <div *ngIf="f.lastname.errors.required">A Vezetéknév kitöltése kötelező</div>
            </div>
          </div>

          <div class="form-group">
            <label for="firstname" class="form-label">Keresztnév</label>
            <div class="input-group input-group-merge">
              <div class="input-group-prepend">
                  <span class="input-group-text" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }">
                    <span [data-feather]="'user'"></span>
                  </span>
              </div>
              <input
                      id="firstname"
                      type="text"
                      formControlName="firstname"
                      class="form-control"
                      placeholder="Keresztnév"
                      aria-describedby="firstname"
                      tabindex="5"
                      [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }"
              />
            </div>
            <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
              <div *ngIf="f.firstname.errors.required">A Keresztnév kitöltése kötelező</div>
            </div>
          </div>

          <div class="form-group">
            <label for="birth_name" class="form-label">Születési név</label>
            <div class="input-group input-group-merge">
              <div class="input-group-prepend">
                  <span class="input-group-text">
                    <span [data-feather]="'user'"></span>
                  </span>
              </div>
              <input
                      id="birth_name"
                      type="text"
                      formControlName="birth_name"
                      class="form-control"
                      placeholder="Születési név"
                      aria-describedby="birth_name"
                      tabindex="6"
              />
            </div>
          </div>

          <div class="form-group">
            <label for="mothers_name" class="form-label">Anyja neve</label>
            <div class="input-group input-group-merge">
              <div class="input-group-prepend">
                  <span class="input-group-text">
                    <span [data-feather]="'users'"></span>
                  </span>
              </div>
              <input
                      id="mothers_name"
                      type="text"
                      formControlName="mothers_name"
                      class="form-control"
                      placeholder="Anyja neve"
                      aria-describedby="mothers_name"
                      tabindex="7"
              />
            </div>
          </div>

          <div class="form-group">
            <label for="birth_place" class="form-label">Születési hely</label>
            <div class="input-group input-group-merge">
              <div class="input-group-prepend">
                  <span class="input-group-text">
                    <span [data-feather]="'map-pin'"></span>
                  </span>
              </div>
              <input
                      id="birth_place"
                      type="text"
                      formControlName="birth_place"
                      class="form-control"
                      placeholder="Születési hely"
                      aria-describedby="birth_place"
                      tabindex="8"
              />
            </div>
          </div>

          <div class="form-group">
            <label for="birth_date" class="form-label">Születési idő</label>
            <div class="input-group input-group-merge">
              <div class="input-group-prepend">
                  <span class="input-group-text">
                    <span [data-feather]="'calendar'"></span>
                  </span>
              </div>
              <input
                      [(ngModel)]="BirthDateDPdata"
                      ngbDatepicker [dayTemplate]="customDay" [markDisabled]="isDisabled"
                      [minDate]="minDate" [maxDate]="maxDate" [startDate]="startDate"
                      #BirthDateDP="ngbDatepicker"
                      id="birth_date"
                      type="text"
                      formControlName="birth_date"
                      class="form-control"
                      placeholder="éééé-hh-nn"
                      aria-describedby="birth_date"
                      tabindex="9"
              />
              <div class="input-group-append">
                <button class="btn btn-outline-primary-light feather icon-calendar" (click)="BirthDateDP.toggle()"
                        type="button" rippleEffect></button>
              </div>
              <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled"
                           let-focused="focused">
                <span class="custom-day" [class.weekend]="isWeekend(date)" [class.focused]="focused"
                      [class.bg-primary]="selected" [class.hidden]="date.month !== currentMonth" [class.text-muted]="disabled">
                  {{ date.day }}
                </span>
              </ng-template>
            </div>
          </div>

          <div class="form-group">
            <label for="phone" class="form-label">Telefonszám</label>
            <div class="input-group input-group-merge">
              <div class="input-group-prepend">
                  <span class="input-group-text">
                    <span [data-feather]="'phone'"></span>
                  </span>
              </div>
              <input
                      id="phone"
                      type="text"
                      formControlName="phone"
                      class="form-control"
                      placeholder="Telefonszám"
                      aria-describedby="phone"
                      tabindex="10"
              />
            </div>
          </div>

          <div class="form-group">
            <label for="tax_id_number" class="form-label">Adószám</label>
            <div class="input-group input-group-merge">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <span [data-feather]="'file-text'"></span>
                  </span>
                </div>
              <input
                      id="tax_id_number"
                      type="text"
                      formControlName="tax_id_number"
                      class="form-control"
                      placeholder="Adószám"
                      aria-describedby="tax_id_number"
                      tabindex="11"
              />
            </div>
          </div>


<!--          <div class="form-group">-->
<!--            <label for="prime_number" class="form-label">Törzsszám</label>-->
<!--            <div class="input-group input-group-merge">-->
<!--              <div class="input-group-prepend">-->
<!--                  <span class="input-group-text">-->
<!--                    <span [data-feather]="'file-text'"></span>-->
<!--                  </span>-->
<!--              </div>-->
<!--              <input-->
<!--                      id="prime_number"-->
<!--                      type="text"-->
<!--                      formControlName="prime_number"-->
<!--                      class="form-control"-->
<!--                      placeholder="Törzsszám"-->
<!--                      aria-describedby="prime_number"-->
<!--                      tabindex="12"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
          <br><br>
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input
                      class="custom-control-input"
                      type="checkbox"
                      id="accept_privacy_policy"
                      tabindex="13"
                      formControlName="accept_privacy_policy"
                      [ngClass]="{ 'is-invalid error': submitted && f.accept_privacy_policy.errors }"
              />
              <label class="custom-control-label" for="accept_privacy_policy">
                Elfogadom az <a href="assets/documents/NSZ-adatkezelesi_szabalyzat_20180525.pdf" target="_blank" rel="nofollow">Adatkezelési szabályzatot</a>
              </label>
            </div>
            <div
              *ngIf="submitted && f.accept_privacy_policy.errors"
              class="invalid-feedback"
              [ngClass]="{ 'd-block': submitted && f.accept_privacy_policy.errors }"
            >
              <div *ngIf="f.accept_privacy_policy.errors.required">Kérem, fogadja el az adatkezelési szabályzatot</div>
            </div>
          </div>
          <button class="btn btn-primary btn-block" tabindex="14" rippleEffect>Regisztrálok</button>
        </form>
        <p class="text-center mt-2">
          <span>Már regisztrált? </span>
          <a routerLink="/pages/authentication/login">
            <span> Lépjen be</span>
          </a>
        </p>
      </div>
    </div>
    <!-- /Register-->
  </div>
</div>
