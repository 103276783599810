import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {CoreCommonModule} from '@core/common.module';

import {AuthRegisterV1Component} from "app/main/pages/authentication/auth-register-v1/auth-register-v1.component";
import {AuthLoginV1Component} from 'app/main/pages/authentication/auth-login-v1/auth-login-v1.component';
import {AuthForgotPasswordV1Component} from "./auth-forgot-password-v1/auth-forgot-password-v1.component";
import {VerifyEmailComponent} from "./verify-email/verify-email.component";
import {AuthResetPasswordV1Component} from "./auth-reset-password-v1/auth-reset-password-v1.component";

// routing
const routes: Routes = [
	{
		path: 'authentication/register',
		component: AuthRegisterV1Component,
		data: {animation: 'auth'}
	},
	{
		path: 'authentication/login',
		component: AuthLoginV1Component,
		data: {animation: 'auth'}
	},
	{
		path: 'authentication/forgot-password',
		component: AuthForgotPasswordV1Component,
		data: {animation: 'auth'}
	},
	{
		path: 'authentication/verify-email',
		component: VerifyEmailComponent,
		data: {animation: 'auth'}
	},
	{
		path: 'authentication/reset-password',
		component: AuthResetPasswordV1Component,
		data: {animation: 'auth'}
	}
];

@NgModule({
	declarations: [
		AuthRegisterV1Component,
		AuthLoginV1Component,
		AuthForgotPasswordV1Component,
		VerifyEmailComponent,
		AuthResetPasswordV1Component
	],
	imports: [
		CommonModule,
		RouterModule.forChild(routes),
		TranslateModule,
		NgbModule,
		FormsModule,
		ReactiveFormsModule,
		CoreCommonModule
	]
})
export class AuthenticationModule {
}
