import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Sector} from "../../../auth/models/sector";
import {SectorService} from "../sector.service";
import {ActivatedRoute} from "@angular/router";

@Component({
	selector: 'app-sector-edit.edit-modal',
	templateUrl: './sector-edit-modal-form.component.html',
	styleUrls: ['./sector-edit-modal-form.component.scss']
})

export class SectorEditModalFormComponent implements OnInit {
	@Input() public id: number;
	@Input() public isAddNew: boolean;

	private data: any;
	private result: any;
	public sector = new Sector();

	constructor(
		private route: ActivatedRoute,
		private sectorService: SectorService,
		public activeModal: NgbActiveModal,
	) {
	}

	ngOnInit() {
		if (!this.isAddNew) {
			this.getSector();
		}
	}

	onSubmit(isValid) {
		// stop here if form is invalid
		if (!isValid) {
			return;
		}

		if (this.sector && this.sector.id) {
			// *** Update *** //
			this.sectorService.update(this.id, this.sector)
				.subscribe(
					() => {
						this.result = {status: true, crudType: 'u'};
						this.activeModal.close(this.result);
					},
					(res) => {
						this.result = {status: false, message: res};
						this.activeModal.close(this.result);
					}
				);
		}
		else {
			// *** Create *** //
			this.sectorService.create(this.sector)
				.subscribe(
					() => {
						this.result = {status: true, crudType: 'c'};
						this.activeModal.close(this.result);
					},
					(res) => {
						this.result = {status: false, message: res};
						this.activeModal.close(this.result);
					}
				);
		}
	}

	private getSector() {
		this.sectorService.getSectorById(this.id).subscribe(
			res => {
				this.data = res;
				this.sector = this.data;
		})
	}
}
