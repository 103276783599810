<div class="card profile-main-card">
    <div class="heading-elements heading-elements-profile">
        <ul class="list-inline mb-0">
            <li class="ng-star-inserted"><a (click)="activeModal.close(null)"><i class="feather icon-x" ></i></a></li>
        </ul>
    </div>
    <!-- Title -->
    <div class="col-lg-12 col-md-12 col-12 row-title">
        <h3 class="card-title address-edit">{{ address.id ? viewOnly ? "Cím megtekintése" : "Cím szerkesztése" : "Cím hozzáadása" }}</h3>
    </div>
    <!-- Profile -->
    <div *ngIf="address.id" class="col-lg-12 col-md-12 col-12">
        <div class="card card-profile address-profile">
            <div class="card-body">
                <div class="profile-image-wrapper">
                    <div class="profile-image">
                        <div class="avatar">
                            <div *ngIf="address.avatar; else customAvatar">
                                <img src="{{ frontendUrl + '/profile_pics/' + address.avatar }}" alt="Profile Picture" />
                            </div>
                        </div>
                        <ng-template #customAvatar>
                            <div class="avatar avatar-address mr-1 ml-0 bg-light-primary no-margin">
                                <div class="avatar-content avatar-content-100">{{ address.lastname + ' ' + address.firstname | initials }}</div>
                            </div>
                        </ng-template>
                    </div>
                </div>
                <h3>{{ address.name_prefix }} {{ address.lastname }} {{ address.firstname }}</h3>
            </div>
        </div>
    </div>
    <!-- End Profile -->

    <!-- Form - Address Data -->
    <div class="col-lg-12 col-md-12 col-12">
        <form class="form form-horizontal address-edit" (ngSubmit)="onSubmit(AddressValidationForm.form.valid);" #AddressValidationForm="ngForm">
            <fieldset [disabled]="viewOnly">
                <div *ngIf="isAddNew && isAdmin" class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <div class="col-sm-12 col-form-label">
                                <h4 class="text-center">{{ 'TABLE_HEADER.CHOOSE_MEMBERS' | translate }}</h4>
                            </div>
                            <div class="col-sm-2"></div>
                            <div class="col-lg-8">
                                <ng-select [items]="selectMember" bindLabel="fullname" bindValue="id"
                                           [loading]="selectMemberLoading" [clearable]="false" tabIndex="0" id="user_id"
                                           (change)="selectMemberChange($event)"
                                > </ng-select>
                            </div>
                            <div class="col-sm-2"></div>
                            <div class="col-sm-12 col-form-label">
                                <p class="font-italic text-center">{{ 'TABLE_HEADER.SEARCH_HELP' | translate }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="divider">
                    <div class="divider-text divider-primary">{{ 'TABLE_HEADER.ADDRESS_INFORMATIONS' | translate }}</div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label">
                                <label>{{ 'TABLE_HEADER.ADDRESS_TYPE' | translate }}</label>
                            </div>
                            <div class="col-sm-9">
                                <ng-select [items]="addressTypeIcon" [(ngModel)]="selectedAddressType"
                                           tabindex="1"
                                           id="address_type" name="address_type" bindLabel="name" bindValue="id"
                                           [clearable]="false"
                                           (change)="selectAddressChange($event)"
                                >
                                    <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                        <i class="select_item_icon" [class]="item.icon"></i>
                                        {{ item.name }}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label">
                                <label>{{ 'TABLE_HEADER.COUNTRY' | translate }}</label>
                            </div>
                            <div class="col-sm-9">
                                <div class="input-group input-group-merge">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text" [class.error]="AddressValidationForm.submitted && TDCountryRef.invalid">
                                        <span [data-feather]="'map-pin'"></span>
                                    </span>
                                    </div>
                                    <input
                                            id="country"
                                            type="text"
                                            name="country"
                                            placeholder="{{ 'TABLE_HEADER.COUNTRY' | translate }}"
                                            class="form-control"
                                            [class.error]="AddressValidationForm.submitted && TDCountryRef.invalid"
                                            tabindex="2"
                                            ngModel #TDCountryRef="ngModel"
                                            required
                                            [(ngModel)]="address.country"
                                    />
                                </div>
                            </div>
                            <div class="col-sm-9 offset-sm-3">
                            <span *ngIf="AddressValidationForm.submitted && TDCountryRef.invalid" class="invalid-form">
                                <small class="form-text text-danger">Az 'Ország' kitöltése kötelező!</small>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label">
                                <label>{{ 'TABLE_HEADER.ZIP_CODE_LONG' | translate }}</label>
                            </div>
                            <div class="col-lg-3 col-sm-5">
                                <div class="input-group input-group-merge">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text" [class.error]="AddressValidationForm.submitted && TDZipCodeRef.invalid">
                                        <span [data-feather]="'file-text'"></span>
                                    </span>
                                    </div>
                                    <input
                                            id="zip_code"
                                            type="text"
                                            name="zip_code"
                                            placeholder="{{ 'TABLE_HEADER.ZIP_CODE' | translate }}"
                                            class="form-control"
                                            [class.error]="AddressValidationForm.submitted && TDZipCodeRef.invalid"
                                            tabindex="3"
                                            ngModel #TDZipCodeRef="ngModel"
                                            required
                                            [(ngModel)]="address.zip_code"
                                    />
                                </div>
                            </div>
                            <div class="col-sm-9 offset-sm-3">
                            <span *ngIf="AddressValidationForm.submitted && TDZipCodeRef.invalid" class="invalid-form">
                                <small class="form-text text-danger">Az 'Irányítószám' kitöltése kötelező!</small>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label">
                                <label>{{ 'TABLE_HEADER.CITY' | translate }}</label>
                            </div>
                            <div class="col-sm-9">
                                <div class="input-group input-group-merge">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text" [class.error]="AddressValidationForm.submitted && TDCityRef.invalid">
                                        <span [data-feather]="'file-text'"></span>
                                    </span>
                                    </div>
                                    <input
                                            id="city"
                                            type="text"
                                            name="city"
                                            placeholder="{{ 'TABLE_HEADER.CITY' | translate }}"
                                            class="form-control"
                                            [class.error]="AddressValidationForm.submitted && TDCityRef.invalid"
                                            tabindex="4"
                                            ngModel #TDCityRef="ngModel"
                                            required
                                            [(ngModel)]="address.city"
                                    />
                                </div>
                            </div>
                            <div class="col-sm-9 offset-sm-3">
                            <span *ngIf="AddressValidationForm.submitted && TDCityRef.invalid" class="invalid-form">
                                <small class="form-text text-danger">A 'Város' kitöltése kötelező!</small>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label">
                                <label>{{ 'TABLE_HEADER.STREET' | translate }}</label>
                            </div>
                            <div class="col-sm-9">
                                <div class="input-group input-group-merge">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text" [class.error]="AddressValidationForm.submitted && TDStreetRef.invalid">
                                        <span [data-feather]="'file-text'"></span>
                                    </span>
                                    </div>
                                    <input
                                            id="street"
                                            type="text"
                                            name="street"
                                            placeholder="{{ 'TABLE_HEADER.STREET' | translate }}"
                                            class="form-control"
                                            [class.error]="AddressValidationForm.submitted && TDStreetRef.invalid"
                                            tabindex="5"
                                            ngModel #TDStreetRef="ngModel"
                                            required
                                            [(ngModel)]="address.street"
                                    />
                                </div>
                            </div>
                            <div class="col-sm-9 offset-sm-3">
                            <span *ngIf="AddressValidationForm.submitted && TDStreetRef.invalid" class="invalid-form">
                                <small class="form-text text-danger">Az 'Utca' kitöltése kötelező!</small>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label">
                                <label>{{ 'TABLE_HEADER.NUMBER' | translate }}</label>
                            </div>
                            <div class="col-lg-3 col-sm-5">
                                <div class="input-group input-group-merge">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text" [class.error]="AddressValidationForm.submitted && TDNumberRef.invalid">
                                        <span [data-feather]="'file-text'"></span>
                                    </span>
                                    </div>
                                    <input
                                            id="number"
                                            type="text"
                                            name="number"
                                            placeholder="{{ 'TABLE_HEADER.NUMBER' | translate }}"
                                            class="form-control"
                                            [class.error]="AddressValidationForm.submitted && TDNumberRef.invalid"
                                            tabindex="6"
                                            ngModel #TDNumberRef="ngModel"
                                            required
                                            [(ngModel)]="address.number"
                                    />
                                </div>
                            </div>
                            <div class="col-sm-9 offset-sm-3">
                            <span *ngIf="AddressValidationForm.submitted && TDNumberRef.invalid" class="invalid-form">
                                <small class="form-text text-danger">A 'Házszám' kitöltése kötelező!</small>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label">
                                <label>{{ 'TABLE_HEADER.BUILDING' | translate }}</label>
                            </div>
                            <div class="col-lg-3 col-sm-5">
                                <div class="input-group input-group-merge">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <span [data-feather]="'file-text'"></span>
                                    </span>
                                    </div>
                                    <input
                                        id="building"
                                        type="text"
                                        name="building"
                                        placeholder="{{ 'TABLE_HEADER.BUILDING' | translate }}"
                                        class="form-control"
                                        tabindex="7"
                                        [(ngModel)]="address.building"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label">
                                <label>{{ 'TABLE_HEADER.STAIRCASE' | translate }}</label>
                            </div>
                            <div class="col-lg-3 col-sm-5">
                                <div class="input-group input-group-merge">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <span [data-feather]="'file-text'"></span>
                                    </span>
                                    </div>
                                    <input
                                            id="staircase"
                                            type="text"
                                            name="staircase"
                                            placeholder="{{ 'TABLE_HEADER.STAIRCASE' | translate }}"
                                            class="form-control"
                                            tabindex="8"
                                            [(ngModel)]="address.staircase"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label">
                                <label>{{ 'TABLE_HEADER.FLOOR' | translate }}</label>
                            </div>
                            <div class="col-lg-3 col-sm-5">
                                <div class="input-group input-group-merge">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <span [data-feather]="'file-text'"></span>
                                    </span>
                                    </div>
                                    <input
                                            id="floor"
                                            type="text"
                                            name="floor"
                                            placeholder="{{ 'TABLE_HEADER.FLOOR' | translate }}"
                                            class="form-control"
                                            tabindex="9"
                                            [(ngModel)]="address.floor"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label">
                                <label>{{ 'TABLE_HEADER.DOOR' | translate }}</label>
                            </div>
                            <div class="col-lg-3 col-sm-5">
                                <div class="input-group input-group-merge">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <span [data-feather]="'file-text'"></span>
                                    </span>
                                    </div>
                                    <input
                                            id="door"
                                            type="text"
                                            name="door"
                                            placeholder="{{ 'TABLE_HEADER.DOOR' | translate }}"
                                            class="form-control"
                                            tabindex="10"
                                            [(ngModel)]="address.door"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
            <br><br>
            <div class="col-sm-9 offset-sm-3">
                <button tabindex="11" type="submit" rippleEffect class="btn btn-primary mr-1" [disabled]="viewOnly">Beküld</button>
                <button tabindex="12" type="button" rippleEffect class="btn btn-outline-secondary" (click)="activeModal.close(null)">Mégsem</button>
            </div>
        </form>
    </div>
    <!-- End Form - Personal Data -->
</div>
