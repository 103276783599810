export const locale = {
    lang: 'hu',
    data: {
        MEMBER: {
            ACTIONS: 'Műveletek',
            ADD_NEW_RECORDS: 'Új tag',
            EXPORT_CSV: 'Exportálás CSV-be',
            SHOW: 'Mutat',
            ENTRIES: 'bejegyzést',
            SEARCH: 'Keresés',
            SEARCH_TYPE: 'Név vagy Tagazonosító',
        },
        TABLE_HEADER: {
            ID: 'Id',
            MEMBER_ID: 'Tagazonosító',
            STATUS: 'Státusz',
            FULL_NAME: 'Név',
            NAME_PREFIX: 'Előtag',
            LASTNAME: 'Vezetéknév',
            FIRSTNAME: 'Keresztnév',
            EMAIL: 'E-mail',
            PHONE: 'Telefonszám',
            BIRTH_NAME: 'Születési név',
            MOTHERS_NAME: 'Anyja neve',
            BIRTH_PLACE: 'Születési hely',
            BIRTH_DATE: 'Születési dátum',
            TAX_ID_NUMBER: 'Adószám',
            PRIME_NUMBER: 'Törzsszám',
            DELETION_REASON: 'A törlés oka',
            CREATED_AT: 'Létrehozva',
            UPDATED_AT: 'Módosítva',
            DELETED_AT: 'Törölve',
            ACTIONS: 'Műveletek',
            PERSONAL_INFORMATIONS: 'Személyes adatok',
            ROLE: 'Szerepkör',
            DIVISIONS: 'Tagozatok',
            SECTORS: 'Ágazatok',
            WORKPLACES: 'Munkahelyek',
            USER_ADDRESSES: 'Címek',
            USER_DIVISIONS: 'Tagozatok',
            USER_SECTORS: 'Ágazatok',
            USER_WORKPLACES: 'Munkahelyek',
        }
    }
};
