import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from 'environments/environment';
import {User, Role} from 'app/auth/models';
import {ToastrService} from 'ngx-toastr';
import {Router} from "@angular/router";
import {StorageService} from "./storage.service";

@Injectable({providedIn: 'root'})
export class AuthenticationService {
	//public
	public currentUser: Observable<User>;
	public currentUserToken: Observable<string>;
	public currentRole: Observable<Role>;

	//private
	private currentUserSubject: BehaviorSubject<User>;
	private currentUserTokenSubject: BehaviorSubject<string>;
	private currentRoleSubject: BehaviorSubject<Role>;

	/**
	 *
	 * @param {HttpClient} _http
	 * @param _router
	 * @param _storageService
	 * @param {ToastrService} _toastrService
	 */
	constructor(
		private _http: HttpClient,
		private _router: Router,
		private _storageService: StorageService,
		private _toastrService: ToastrService) {
		this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
		this.currentUser = this.currentUserSubject.asObservable();

		this.currentUserTokenSubject = new BehaviorSubject<string>(JSON.parse(localStorage.getItem('currentUserToken')));
		this.currentUserToken = this.currentUserTokenSubject.asObservable();

		this.currentRoleSubject = new BehaviorSubject<Role>(JSON.parse(localStorage.getItem('currentRole')));
		this.currentRole = this.currentRoleSubject.asObservable();
	}

	public get currentUserValue(): User {
		return this.currentUserSubject.value;
	}

	public get currentUserTokenValue(): string {
		return this.currentUserTokenSubject.value;
	}

	public get currentRoleValue(): Role {
		return this.currentRoleSubject.value;
	}

	/**
	 * User Register
	 *
	 * @param email
	 * @param password
	 * @param name_prefix
	 * @param lastname
	 * @param firstname
	 * @param birth_name
	 * @param mothers_name
	 * @param birth_place
	 * @param birth_date
	 * @param phone
	 * @param tax_id_number
	 * @param prime_number
	 * @returns user
	 */
	register(email: string, password: string, name_prefix: string, lastname: string, firstname: string, birth_name: string,
	         mothers_name: string, birth_place: string, birth_date: string, phone: string, tax_id_number: string, prime_number: string): Observable<any> {
		return this._http.post(`${environment.apiUrl}/register`, {
			email,
			password,
			name_prefix,
			lastname,
			firstname,
			birth_name,
			mothers_name,
			birth_place,
			birth_date,
			phone,
			tax_id_number,
			prime_number
		});
	}


	/**
	 * User login
	 *
	 * @param email
	 * @param password
	 * @returns user
	 */
	login(email: string, password: string) {
		return this._http
			.post<any>(`${environment.apiUrl}/login`, {email, password})
			.pipe(
				map(user => {
					// login successful if there's a jwt token in the response
					if (user && user.token) {
						// store user details and jwt token in local storage to keep user logged in between page refreshes
						localStorage.setItem('currentUser', JSON.stringify(user.user));
						localStorage.setItem('currentUserToken', JSON.stringify(user.token));
						localStorage.setItem('currentRole', JSON.stringify(user.user_roles[0]));
						// notify
						this.currentUserSubject.next(user.user);
						this.currentUserTokenSubject.next(user.token);
						this.currentRoleSubject.next(user.user_roles[0]);

						// develop - token
						// console.log(user.token);
						// Display welcome toast!
						setTimeout(() => {
							this._toastrService.success(
								'Sikeresen bejelentkeztél, mint ' + user.user_roles[0].role_name + ' 🎉',
								'👋 Üdvözöllek, ' + user.user.firstname + '!',
								{toastClass: 'toast ngx-toastr', closeButton: true}
							);
						}, 1000);
						return user;
					}
				})
			);
	}

	/**
	 * User logout
	 *
	 */
	logout() {
		this._http.get(`${environment.apiUrl}/logout`, {observe: 'response'})
			.pipe(map((res: any) => res.body)).subscribe(
			(/*res*/) => {
				localStorage.removeItem('currentUser');
				localStorage.removeItem('currentUserToken');
				localStorage.removeItem('currentRole');
				// notify
				this.currentUserSubject.next(null);
				this.currentUserTokenSubject.next(null);
				this.currentRoleSubject.next(null);
				this._storageService.clean();

				this._router.navigate(['/pages/authentication/login']).then((/*res*/) => {
					setTimeout(() => {
						this._toastrService.success(
							'Sikeresen kijeletkeztél a NESZE CRM alkalmzásból.',
							'Sikeres kijelentkezés!',
							{toastClass: 'toast ngx-toastr', closeButton: true}
						);
					}, 200);
				});
			},
			(/*error*/) => {
				setTimeout(() => {
					this._toastrService.error(
						'Hiba történt a kijelentkezés során! Kérlek, próbáld meg újra a kijelenkezést!',
						'Sikertelen kijelentkezés!',
						{toastClass: 'toast ngx-toastr', closeButton: true, disableTimeOut: true}
					);
				}, 200);
			}
		);
	}

	/**
	 * User E-mail verification
	 *
	 * @param user_id
	 * @param verification_code
	 */
	verify_email(user_id, verification_code) {
		return this._http.post<any>(`${environment.apiUrl}/verifyemail`, {
			user_id,
			verification_code
		}, {observe: 'response'})
	}

	/**
	 * User Forgotten Password
	 *
	 * @param email
	 * @returns user
	 */
	forgotten_password(email: string): Observable<any> {
		return this._http.post(`${environment.apiUrl}/forgottenpasswd`, { email });
	}

	/**
	 * User Reset Password
	 *
	 * @returns user
	 * @param password
	 * @param password_again
	 * @param user_id
	 * @param verification_link
	 */
	password_reset(password: string, password_again: string, user_id: string, verification_link: string): Observable<any> {
		return this._http.post(`${environment.apiUrl}/passwordreset`, { password, password_again, user_id, verification_link });
	}

}
