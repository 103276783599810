export const locale = {
    lang: 'en',
    data: {
        DIVISION: {
            ACTIONS: 'Actions',
            ADD_NEW_RECORDS: 'New division',
            EXPORT_CSV: 'Export CSV',
            SHOW: 'Show',
            ENTRIES: 'entries',
            SEARCH: 'Search',
        },
        TABLE_HEADER: {
            ID: 'Id',
            DIVISION_NAME: 'Division name',
            CREATED_AT: 'Created at',
            UPDATED_AT: 'Updated at',
            DELETED_AT: 'Deleted at',
            ACTIONS: 'Actions',
        }
    }
};
