import {HttpClient, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';
import {environment} from "../../../environments/environment";

@Injectable()
export class AccountSettingsService implements Resolve<any> {
  rows: any;
  onSettingsChanged: BehaviorSubject<any>;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    // Set the defaults
    this.onSettingsChanged = new BehaviorSubject({});
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getDataTableRows()]).then(() => {
        resolve();
      }, reject);
    });
  }

  /**
   * Get rows
   */
  getDataTableRows(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/account`).subscribe((response: any) => {
        this.rows = response;
        this.onSettingsChanged.next(this.rows);
        resolve(this.rows);
      }, reject);
    });
  }

  update(id: number, data: any): Observable<HttpResponse<any>> {
    return this._httpClient.put(`${environment.apiUrl}/user/${id}`, data, {observe: 'response'});
  }

  changepasswd(data: any): Observable<HttpResponse<any>> {
    return this._httpClient.post(`${environment.apiUrl}/changepasswd`, data, {observe: 'response'});
  }

  changeprofilepic(id: number, data: any): Observable<HttpResponse<any>> {
    return this._httpClient.post(`${environment.apiUrl}/user/${id}/changeprofilepic`, data, {observe: 'response'});
  }

  deleteprofilepic(id: number): Observable<HttpResponse<any>> {
    return this._httpClient.delete(`${environment.apiUrl}/user/${id}/deleteprofilepic`, {observe: 'response'});
  }

  deleteaddress(id: number): Observable<HttpResponse<any>> {
    return this._httpClient.delete(`${environment.apiUrl}/address/${id}`, {observe: 'response'});
  }

}
