<div class="auth-wrapper auth-v1 px-2">
  <div class="auth-inner py-2">
    <!-- Login v1 -->
    <div class="card mb-0">
      <div class="card-body">
        <div class="row d-flex align-items-center justify-content-center mb-2">
  <!--          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="28" />-->
            <img src="assets/images/logo/crm-logo.png" alt="logo" height="64" />
            <h2 class="brand-text text-primary ml-1">{{ coreConfig.app.appName }}</h2>
        </div>

        <h4 class="card-title mb-1 center-layout">{{ 'AUTHENTICATION.CARD_TITLE' | translate }} 🔒</h4>
        <p class="card-text mb-2">{{ 'AUTHENTICATION.CARD_TEXT' | translate }}</p>
        <form class="auth-login-form mt-2" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="login-email" class="form-label">{{ 'AUTHENTICATION.EMAIL_LABEL' | translate}}</label>
            <input
              type="text"
              formControlName="email"
              class="form-control"
              placeholder="john@example.com"
              aria-describedby="login-email"
              tabindex="1"
              autofocus
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
            />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">{{ 'AUTHENTICATION.EMAIL_MESSAGE_REQUIRED' | translate  }}</div>
              <div *ngIf="f.email.errors.email">{{ 'AUTHENTICATION.EMAIL_MESSAGE_INVALID' | translate  }}</div>
            </div>
          </div>

          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="login-password">{{ 'AUTHENTICATION.PASSWORD_LABEL' | translate  }}</label>
              <a routerLink="/pages/authentication/forgot-password">
                <small>{{ 'AUTHENTICATION.FORGOT_PASSWORD_LABEL' | translate  }}</small>
              </a>
            </div>
            <div class="input-group input-group-merge form-password-toggle">
              <input
                [type]="passwordTextType ? 'text' : 'password'"
                formControlName="password"
                class="form-control form-control-merge"
                tabindex="2"
                placeholder="·········"
                aria-describedby="login-password"
                [ngClass]="{ 'is-invalid error': submitted && f.password.errors }"
              />

              <div class="input-group-append">
                <span class="input-group-text cursor-pointer"
                  ><i
                    class="feather font-small-4"
                    [ngClass]="{
                      'icon-eye-off': passwordTextType,
                      'icon-eye': !passwordTextType
                    }"
                    (click)="togglePasswordTextType()"
                  ></i
                ></span>
              </div>
            </div>
            <div
              *ngIf="submitted && f.password.errors"
              class="invalid-feedback"
              [ngClass]="{ 'd-block': submitted && f.password.errors }"
            >
              <div *ngIf="f.password.errors.required"></div>
            </div>
          </div>
          <button class="btn btn-primary btn-block" tabindex="4" rippleEffect>{{'AUTHENTICATION.SIGN_IN_BUTTON' | translate }}</button>
        </form>

        <p class="text-center mt-2">
          <span>{{'AUTHENTICATION.NEW_ON_OUR_PLATFORM' | translate }}</span>
          <a routerLink="/pages/authentication/register">
            <span>&nbsp;{{ 'AUTHENTICATION.REGISTER_LINK' | translate }}</span>
          </a>
        </p>
      </div>
    </div>
    <!-- /Login v1 -->
  </div>
</div>
