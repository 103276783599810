<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <!--suppress TypeScriptValidateTypes -->
      <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- account setting page -->
    <section id="page-account-settings">
      <div class="row nav-vertical d-flex">
        <div class="col-md-3 mb-2 mb-md-0">
          <ul ngbNav #navVertical="ngbNav" class="nav nav-pills flex-column nav-left">

            <li class="nav-item" ngbNavItem="personal_data">
              <a
                ngbNavLink
                class="nav-link d-flex py-75"
                id="account-pill-general"
                data-toggle="pill"
                href="#personal_data"
                aria-expanded="true"
              >
                <i data-feather="user" class="font-medium-3 mr-1"></i>
                <span class="font-weight-bold">Személyes adatok</span>
              </a>
              <ng-template ngbNavContent>
                <div class="card">
                  <div class="card-body">
                    <!-- header media -->
                    <div [formGroup]="fileForm" class="media">
                      <a href="javascript:void(0);" class="mr-25">
                        <img [src]="avatarImage" class="rounded mr-50" alt="Profilkép" height="76" width="76" />
                      </a>
                      <!-- upload and reset button -->
                      <div class="media-body mt-75 ml-1">
                        <button
                          (click)="file.click()"
                          class="btn btn-sm btn-secondary mb-75 mr-75"
                          rippleEffect
                        >
                          Kiválaszt
                        </button>
                        <input
                          formControlName="profilePic"
                          type="file"
                          #file
                          id="account-upload"
                          hidden
                          accept="image/*"
                          (change)="uploadImage($event)"
                        />
                        <button (click)="resetProfilePic()" class="btn btn-sm btn-outline-secondary mb-75 mr-75" rippleEffect>Visszaállít</button>
                        <button (click)="changeProfilePic();" class="btn btn-sm btn-primary mb-75 mr-75" rippleEffect>Feltölt</button>
                        <button (click)="deleteProfilePic();" class="btn btn-sm btn-primary mb-75" rippleEffect>Töröl</button>
                        <p>Megengedett JPG, PNG. Maximális méret 800kB</p>
                      </div>
                      <!--/ upload and reset button -->
                    </div>
                    <!--/ header media -->

                    <!-- form -->
                    <form class="validate-form mt-2" (ngSubmit)="onSubmitPersonalData(AccountValidationForm.form.valid);" #AccountValidationForm="ngForm">
                      <div class="row">
                        <div class="col-6 col-sm-6 offset-sm-3">
                          <div class="form-group">
                            <label for="member_id">{{ 'TABLE_HEADER.MEMBER_ID' | translate }}</label>
                            <div class="input-group input-group-merge">
                              <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <span [data-feather]="'shield'"></span>
                                    </span>
                              </div>
                              <input
                                      autofocus
                                      id="member_id"
                                      type="text"
                                      name="member_id"
                                      class="form-control"
                                      disabled
                                      [(ngModel)]="accountData.member_id"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                              <label for="member_id">{{ 'TABLE_HEADER.NAME_PREFIX' | translate }}</label>
                              <div class="input-group input-group-merge">
                                <div class="input-group-prepend">
                                  <span class="input-group-text"><span [data-feather]="'user'"></span></span>
                                </div>
                                <input
                                        id="name_prefix"
                                        type="text"
                                        class="form-control"
                                        name="name_prefix"
                                        tabindex="1"
                                        placeholder="{{ 'TABLE_HEADER.NAME_PREFIX' | translate }}"
                                        [(ngModel)]="accountData.name_prefix"
                                />
                              </div>
                            </div>
                        </div>

                          <div class="col-12 col-sm-6">
                              <div class="form-group">
                              </div>
                          </div>

                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="member_id">{{ 'TABLE_HEADER.LASTNAME' | translate }}</label>
                            <div class="input-group input-group-merge">
                              <div class="input-group-prepend">
                                      <span class="input-group-text" [class.error]="AccountValidationForm.submitted && TDLastnameRef.invalid">
                                          <span [data-feather]="'user'"></span>
                                      </span>
                              </div>
                              <input
                                      id="lastname"
                                      type="text"
                                      name="lastname"
                                      placeholder="{{ 'TABLE_HEADER.LASTNAME' | translate }}"
                                      class="form-control"
                                      [class.error]="AccountValidationForm.submitted && TDLastnameRef.invalid"
                                      tabindex="4"
                                      ngModel #TDLastnameRef="ngModel"
                                      required
                                      [(ngModel)]="accountData.lastname"
                              />
                            </div>
                            <div class="col-12 col-sm-6">
                            <span *ngIf="AccountValidationForm.submitted && TDLastnameRef.invalid" class="invalid-form">
                                <small class="form-text text-danger">A 'Vezetéknév' kitöltése kötelező!</small>
                            </span>
                            </div>
                          </div>
                        </div>

                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="firstname">Keresztnév</label>
                            <div class="input-group input-group-merge">
                              <div class="input-group-prepend">
                                    <span class="input-group-text" [class.error]="AccountValidationForm.submitted && TDFirstnameRef.invalid">
                                        <span [data-feather]="'user'"></span>
                                    </span>
                              </div>
                              <input
                                      id="firstname"
                                      type="text"
                                      name="firstname"
                                      placeholder="{{ 'TABLE_HEADER.FIRSTNAME' | translate }}"
                                      class="form-control"
                                      [class.error]="AccountValidationForm.submitted && TDFirstnameRef.invalid"
                                      tabindex="5"
                                      ngModel #TDFirstnameRef="ngModel"
                                      required
                                      [(ngModel)]="accountData.firstname"
                              />
                            </div>
                            <div class="col-sm-6">
                            <span *ngIf="AccountValidationForm.submitted && TDFirstnameRef.invalid" class="invalid-form">
                                <small class="form-text text-danger">A 'Keresztnév' kitöltése kötelező!</small>
                            </span>
                            </div>
                          </div>
                        </div>

                        <div class="col-12 col-sm-6">
                              <div class="form-group">
                                  <label>{{ 'TABLE_HEADER.BIRTH_NAME' | translate }}</label>
                                  <div class="input-group input-group-merge">
                                      <div class="input-group-prepend">
                                          <span class="input-group-text"><span [data-feather]="'user'"></span></span>
                                      </div>
                                      <input
                                              id="birth_name"
                                              type="text"
                                              class="form-control"
                                              name="birth_name"
                                              tabindex="6"
                                              placeholder="{{ 'TABLE_HEADER.BIRTH_NAME' | translate }}"
                                              [(ngModel)]="accountData.birth_name"
                                      />
                                  </div>
                              </div>
                          </div>

                        <div class="col-12 col-sm-6">
                              <div class="form-group">
                                  <label>{{ 'TABLE_HEADER.MOTHERS_NAME' | translate }}</label>
                                  <div class="input-group input-group-merge">
                                      <div class="input-group-prepend">
                                          <span class="input-group-text"><span [data-feather]="'users'"></span></span>
                                      </div>
                                      <input
                                              id="mothers_name"
                                              type="text"
                                              class="form-control"
                                              name="mothers_name"
                                              tabindex="7"
                                              placeholder="{{ 'TABLE_HEADER.MOTHERS_NAME' | translate }}"
                                              [(ngModel)]="accountData.mothers_name"
                                      />
                                  </div>
                              </div>
                          </div>

                        <div class="col-12 col-sm-6">
                              <div class="form-group">
                                  <label for="email">E-mail</label>
                                  <div class="input-group input-group-merge">
                                      <div class="input-group-prepend">
                                    <span class="input-group-text" [class.error]="AccountValidationForm.submitted && TDEmailRef.invalid">
                                        <span [data-feather]="'mail'"></span>
                                    </span>
                                      </div>
                                      <input
                                              id="email"
                                              type="text"
                                              name="email"
                                              placeholder="{{ 'TABLE_HEADER.EMAIL' | translate }}"
                                              class="form-control"
                                              [class.error]="AccountValidationForm.submitted && TDEmailRef.invalid"
                                              tabindex="2"
                                              ngModel #TDEmailRef="ngModel"
                                              required
                                              pattern="([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})"
                                              [(ngModel)]="accountData.email"
                                      />
                                  </div>
                                  <div class="col-12 col-sm-6">
                                <span *ngIf="AccountValidationForm.submitted && TDEmailRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger">Érvénytelen E-mail cím!</small>
                                </span>
                                  </div>
                              </div>
                          </div>

                        <div class="col-12 col-sm-6">
                              <div class="form-group">
                                  <label for="phone">Telefonszám</label>
                                  <div class="input-group input-group-merge">
                                      <div class="input-group-prepend">
                                    <span class="input-group-text" [class.error]="AccountValidationForm.submitted && TDPhoneRef.invalid">
                                        <span [data-feather]="'phone'"></span>
                                    </span>
                                      </div>
                                      <input
                                              id="phone"
                                              type="text"
                                              name="phone"
                                              tabindex="9"
                                              class="form-control"
                                              [class.error]="AccountValidationForm.submitted && TDPhoneRef.invalid"
                                              ngModel #TDPhoneRef="ngModel"
                                              required
                                              placeholder="{{ 'TABLE_HEADER.PHONE' | translate }}"
                                              [(ngModel)]="accountData.phone"
                                      />
                                  </div>
                                  <div class="col-sm-6">
                                <span *ngIf="AccountValidationForm.submitted && TDPhoneRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger">A 'Telefonszám' kitöltése kötelező!</small>
                                </span>
                                  </div>
                              </div>
                          </div>

                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label>{{ 'TABLE_HEADER.BIRTH_PLACE' | translate }}</label>
                            <div class="input-group input-group-merge">
                              <div class="input-group-prepend">
                                <span class="input-group-text"><span [data-feather]="'map-pin'"></span></span>
                              </div>
                              <input
                                      id="birth_place"
                                      type="text"
                                      class="form-control"
                                      name="birth_place"
                                      tabindex="8"
                                      placeholder="{{ 'TABLE_HEADER.BIRTH_PLACE' | translate }}"
                                      [(ngModel)]="accountData.birth_place"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label>{{ 'TABLE_HEADER.BIRTH_DATE' | translate }}</label>
                            <div class="input-group input-group-merge">
                              <div class="input-group-prepend">
                                <span class="input-group-text"><span [data-feather]="'calendar'"></span></span>
                              </div>
                              <input class="form-control" placeholder="éééé-hh-nn" name="birth_date"
                                     [(ngModel)]="BirthDateDPdata" ngbDatepicker [dayTemplate]="customDay" [markDisabled]="isDisabled"
                                     #BirthDateDP="ngbDatepicker">
                              <div class="input-group-append">
                                <button class="btn btn-outline-secondary feather icon-calendar" (click)="BirthDateDP.toggle()"
                                        type="button" rippleEffect></button>
                              </div>
                              <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled"
                                           let-focused="focused">
                                    <span class="custom-day" [class.weekend]="isWeekend(date)" [class.focused]="focused"
                                          [class.bg-primary]="selected" [class.hidden]="date.month !== currentMonth" [class.text-muted]="disabled">
                                      {{ date.day }}
                                    </span>
                              </ng-template>
                            </div>
                          </div>
                        </div>

                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label>{{ 'TABLE_HEADER.TAX_ID_NUMBER' | translate }}</label>
                              <div class="input-group input-group-merge">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text" [class.error]="AccountValidationForm.submitted && TDTaxidnumberRef.invalid">
                                        <span [data-feather]="'file-text'"></span>
                                    </span>
                                  </div>
                                  <input
                                          id="tax_id_number"
                                          type="text"
                                          name="tax_id_number"
                                          tabindex="10"
                                          class="form-control"
                                          [class.error]="AccountValidationForm.submitted && TDTaxidnumberRef.invalid"
                                          ngModel #TDTaxidnumberRef="ngModel"
                                          required
                                          placeholder="{{ 'TABLE_HEADER.TAX_ID_NUMBER' | translate }}"
                                          [(ngModel)]="accountData.tax_id_number"
                                  />
                              </div>
                              <div class="col-sm-6">
                                <span *ngIf="AccountValidationForm.submitted && TDTaxidnumberRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger">Az 'Adószám' kitöltése kötelező!</small>
                                </span>
                              </div>
                          </div>
                        </div>

                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label>{{ 'TABLE_HEADER.PRIME_NUMBER' | translate }}</label>
                              <div class="input-group input-group-merge">
                                  <div class="input-group-prepend">
                                      <span class="input-group-text"><span [data-feather]="'file-text'"></span></span>
                                  </div>
                                  <input
                                          id="prime_number"
                                          type="text"
                                          class="form-control"
                                          name="prime_number"
                                          tabindex="11"
                                          placeholder="{{ 'TABLE_HEADER.PRIME_NUMBER' | translate }}"
                                          [(ngModel)]="accountData.prime_number"
                                  />
                              </div>
                          </div>
                        </div>

                        <div class="col-12 mt-75" *ngIf="accountData.email_verified_at == null">
                          <div class="alert alert-warning mb-50" role="alert">
                            <h4 class="alert-heading">Az E-mail címe nincs megerősítve. Kérjük, ellenőrizze a postaládáját!</h4>
                            <div class="alert-body">
                              <a href="javascript: void(0);" class="alert-link">Visszaigazolás újraküldése</a>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <button type="submit" class="btn btn-primary mt-2 mr-1" rippleEffect>Módosítások mentése</button>
                        </div>
                      </div>
                    </form>
                    <!--/ form -->
                  </div>
                </div>
              </ng-template>
            </li>

            <li class="nav-item" ngbNavItem="change_password">
              <a
                ngbNavLink
                class="nav-link d-flex py-75"
                id="account-pill-password"
                data-toggle="pill"
                href="#change_password"
                aria-expanded="false"
              >
                <i data-feather="lock" class="font-medium-3 mr-1"></i>
                <span class="font-weight-bold">Jelszó módosítása</span>
              </a>
              <ng-template ngbNavContent>
                <div class="card">
                  <div class="card-body">
                    <!-- form -->
                    <form class="validate-form mt-2" (ngSubmit)="onSubmitPasswordChange(PasswordChangeForm.form.valid);" #PasswordChangeForm="ngForm">
                      <div class="row">
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="old_password">Régi jelszó</label>
                            <div class="input-group form-password-toggle input-group-merge">
                              <input
                                [type]="passwordTextTypeOld ? 'text' : 'password'"
                                class="form-control"
                                id="old_password"
                                name="old_password"
                                [(ngModel)]="old_password"
                                placeholder="Régi jelszó"
                                [class.error]="(TDOldPasswordRef.touched || PasswordChangeForm.submitted) && TDOldPasswordRef.invalid"
                                ngModel #TDOldPasswordRef="ngModel"
                                required
                              />
                              <div class="input-group-append">
                                <span class="input-group-text cursor-pointer"
                                  ><i
                                    class="feather font-small-4"
                                    [ngClass]="{
                                      'icon-eye-off': passwordTextTypeOld,
                                      'icon-eye': !passwordTextTypeOld
                                    }"
                                    (click)="togglePasswordTextTypeOld()"
                                  ></i
                                ></span>
                              </div>
                            </div>
                            <div class="col-12 col-sm-12">
                                <span *ngIf="(TDOldPasswordRef.touched || PasswordChangeForm.submitted) && TDOldPasswordRef.invalid" class="invalid-form">
                                    <small *ngIf="TDOldPasswordRef.errors.required" class="form-text text-danger">
                                      A 'Régi jelszó' kitöltése kötelező!
                                    </small>
                                </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="new_password">Új jelszó</label>
                            <div class="input-group form-password-toggle input-group-merge">
                              <input
                                [type]="passwordTextTypeNew ? 'text' : 'password'"
                                id="new_password"
                                name="new_password"
                                [(ngModel)]="new_password"
                                class="form-control"
                                placeholder="Új jelszó"
                                [class.error]="(TDPasswordRef.touched || PasswordChangeForm.submitted) && TDPasswordRef.invalid"
                                ngModel #TDPasswordRef="ngModel"
                                required
                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                              />
                              <div class="input-group-append">
                                <span class="input-group-text cursor-pointer"
                                  ><i
                                    class="feather font-small-4"
                                    [ngClass]="{
                                      'icon-eye-off': passwordTextTypeNew,
                                      'icon-eye': !passwordTextTypeNew
                                    }"
                                    (click)="togglePasswordTextTypeNew()"
                                  ></i
                                ></span>
                              </div>
                            </div>
                            <div class="col-12 col-sm-12">
                                <span *ngIf="(TDPasswordRef.touched || PasswordChangeForm.submitted) && TDPasswordRef.invalid" class="invalid-form">
                                    <small *ngIf="TDPasswordRef.errors.required" class="form-text text-danger">
                                      Az 'Új jelszó' kitöltése kötelező!
                                    </small>
                                  <small *ngIf="TDPasswordRef.errors.pattern" class="form-text text-danger">
                                    Az 'Új jelszó'-nak tartalmaznia kell legalább egy számot, egy nagybetűt egy kisbetűt, és legalább 8 vagy több karaktert.
                                  </small>
                                </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="new_password_again">Új jelszó mégegyszer</label>
                            <div class="input-group form-password-toggle input-group-merge">
                              <input
                                [type]="passwordTextTypeRetype ? 'text' : 'password'"
                                class="form-control"
                                id="new_password_again"
                                name="new_password_again"
                                [(ngModel)]="new_password_again"
                                placeholder="Új jelszó"
                                [class.error]="(TDPasswordAgainRef.touched || PasswordChangeForm.submitted) && TDPasswordAgainRef.invalid"
                                ngModel #TDPasswordAgainRef="ngModel"
                                required
                                pattern="{{ TDPasswordRef.value }}"
                              />
                              <div class="input-group-append">
                                <span class="input-group-text cursor-pointer"
                                  ><i
                                    class="feather font-small-4"
                                    [ngClass]="{
                                      'icon-eye-off': passwordTextTypeRetype,
                                      'icon-eye': !passwordTextTypeRetype
                                    }"
                                    (click)="togglePasswordTextTypeRetype()"
                                  ></i
                                ></span>
                              </div>
                            </div>
                            <div class="col-12 col-sm-12">
                                <span *ngIf="(TDPasswordAgainRef.touched || PasswordChangeForm.submitted) && TDPasswordAgainRef.invalid" class="invalid-form">
                                    <small *ngIf="TDPasswordAgainRef.errors.required" class="form-text text-danger">Az 'Új jelszó mégegyszer' kitöltése kötelező!</small>
                                    <small *ngIf="TDPasswordAgainRef.errors.pattern" class="form-text text-danger">Az 'Új jelszó' és a 'Új jelszó mégegyszer'nem egyezik meg, vagy tiltott karaktert tartalmaz! (pl.:'?')</small>
                                </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <button type="submit" class="btn btn-primary mr-1 mt-1" rippleEffect>Módosítások mentése</button>
                          <button type="reset" class="btn btn-outline-secondary mt-1" rippleEffect>Mégsem</button>
                        </div>
                      </div>
                    </form>
                    <!--/ form -->
                  </div>
                </div>
              </ng-template>
            </li>

              <li class="nav-item" ngbNavItem="addresses">
                  <a
                          ngbNavLink
                          class="nav-link d-flex py-75"
                          id="account-pill-addresses"
                          data-toggle="pill"
                          href="#addresses"
                          aria-expanded="false"
                  >
                      <i data-feather="map-pin" class="font-medium-3 mr-1"></i>
                      <span class="font-weight-bold">Címek</span>
                  </a>
                  <ng-template ngbNavContent>
                      <div class="card">
                          <div class="card-header">
                              <h4 class="card-title">{{ 'ADDRESS.ACTIONS' | translate }}</h4>
                          </div>
                          <div class="row">
                              <div class="col-12 mb-50">
                                  <button (click)="openModal(null,true, false, false, accountData.id)" class="btn btn-primary ml-1" rippleEffect>
                                      <i class="mr-0 mr-sm-1" data-feather="plus"></i
                                      ><span class="d-none d-sm-inline-block">{{ 'ADDRESS.ADD_NEW_RECORDS' | translate }}</span>
                                  </button>
                              </div>
                              <div class="col-md-6 col-12">
                                  <div class="d-flex justify-content-between align-items-center m-1">
                                      <label class="d-flex align-items-center"
                                      >{{ 'ADDRESS.SHOW' | translate }}
                                          <select [(ngModel)]="basicSelectedOption" class="form-control mx-25">
                                              <option value="10">10</option>
                                              <option value="25">25</option>
                                              <option value="50">50</option>
                                              <option value="100">100</option>
                                          </select>
                                          {{ 'ADDRESS.ENTRIES' | translate }}</label
                                      >
                                  </div>
                              </div>
                              <div class="col-md-6 col-12 d-flex justify-content-md-end">
                                  <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                                      <label class="d-flex align-items-center"
                                      >{{ 'ADDRESS.SEARCH' | translate }}<input
                                              (keyup)="filterUpdate($event)"
                                              (search)="filterUpdate($event)"
                                              class="form-control ml-25"
                                              placeholder="{{ 'ADDRESS.SEARCH_TYPE' | translate }}"
                                              type="search"
                                      /></label>
                                  </div>
                              </div>
                          </div>
                          <div class="card-body">
                              <!-- list -->
                              <ngx-datatable
                                      (activate)="onActivate($event)"
                                      (select)="onSelect($event)"
                                      [columnMode]="ColumnMode.force"
                                      [footerHeight]="50"
                                      [headerHeight]="40"
                                      [limit]="basicSelectedOption"
                                      [rowClass]="getRowClass"
                                      [rowHeight]="58"
                                      [rows]="kitchenSinkRows"
                                      [scrollbarH]="true"
                                      [selectionType]="SelectionType.checkbox"
                                      class="bootstrap core-bootstrap"
                                      rowHeight="auto"
                              >
                                  <!--   Chekbox (Selector)   -->
                                  <ngx-datatable-column
                                          [canAutoResize]="false"
                                          [draggable]="false"
                                          [resizeable]="false"
                                          [sortable]="false"
                                          [width]="50"
                                  >
                                      <ng-template
                                          let-allRowsSelected="allRowsSelected"
                                          let-selectFn="selectFn"
                                          ngx-datatable-header-template
                                      >
                                          <div class="custom-control custom-checkbox">
                                              <input
                                                      (change)="selectFn(!allRowsSelected)"
                                                      [checked]="allRowsSelected"
                                                      class="custom-control-input"
                                                      id="headerChkbxRef"
                                                      type="checkbox"
                                              />
                                              <label class="custom-control-label" for="headerChkbxRef"></label>
                                          </div>
                                      </ng-template>
                                      <ng-template
                                          let-isSelected="isSelected"
                                          let-onCheckboxChangeFn="onCheckboxChangeFn"
                                          let-rowIndex="rowIndex"
                                          ngx-datatable-cell-template
                                      >
                                          <div class="custom-control custom-checkbox">
                                              <input
                                                      (change)="onCheckboxChangeFn($event)"
                                                      [checked]="isSelected"
                                                      class="custom-control-input"
                                                      id="rowChkbxRef{{ rowIndex }}"
                                                      type="checkbox"
                                              />
                                              <label class="custom-control-label" for="rowChkbxRef{{ rowIndex }}"></label>
                                          </div>
                                      </ng-template>
                                  </ngx-datatable-column>

                                  <ngx-datatable-column [width]="100" name="{{ 'TABLE_HEADER.ADDRESS_TYPE' | translate }}">
                                      <ng-template let-row="row" ngx-datatable-cell-template>
                                          <div [ngClass]="{
                                        'badge-light-primary': row.address_type == 'A',
                                        'badge-light-info': row.address_type == 'I',
                                        'badge-light-secondary': row.address_type == 'L'
                                    }"
                                               class="badge badge-pill d-flex align-items-left"
                                               ngbTooltip="A cím típusa. (állandó, ideiglenes, levelezési)"
                                               placement="top"
                                          >
                                              {{ row.address_type == 'A' ? 'állandó' : row.address_type == 'I' ? 'ideiglenes' : row.address_type == 'L' ? 'levelezési' : 'Nincs típus' }}
                                          </div>
                                      </ng-template>
                                  </ngx-datatable-column>

                                  <ngx-datatable-column class="d-flex justify-content-end" [width]="60" name="{{ 'TABLE_HEADER.ZIP_CODE' | translate }}"
                                                        prop="zip_code"></ngx-datatable-column>

                                  <ngx-datatable-column [width]="140" name="{{ 'TABLE_HEADER.CITY' | translate }}"
                                                        prop="city"></ngx-datatable-column>

                                  <ngx-datatable-column [width]="200" name="{{ 'TABLE_HEADER.ADDRESS' | translate }}">
                                      <ng-template let-row="row" ngx-datatable-cell-template>
                                          <div class="cell-line-height">
                                              {{ row.street }} {{ row.number }}
                                              <!--                                {{ row.building != null ? row.building + ' épület' : '' }}-->
                                              <!--                                {{ row.staircase != null ? row.staircase + ' lépcshz' : '' }}-->
                                              <!--                                {{ row.floor != null ? row.floor + ' em' : '' }}-->
                                              <!--                                {{ row.door != null ? row.door + ' ajtó' : '' }}-->

                                          </div>
                                      </ng-template>
                                  </ngx-datatable-column>


                                  <ngx-datatable-column name="{{ 'TABLE_HEADER.UPDATED_AT' | translate }}"
                                                        prop="updated_at"></ngx-datatable-column>

                                  <ngx-datatable-column [sortable]="false" [width]="120"
                                                        name="{{ 'TABLE_HEADER.ACTIONS' | translate }}">
                                      <ng-template
                                          let-row="row"
                                          ngx-datatable-cell-template>
                                          <div *ngIf="row.role_id != 1">
                                              <div *ngIf="row.deleted_at == null else elseBlockActions" class="d-flex align-items-center">
                                                  <button (click)="openModal(row.id, false, true, false, accountData.id)" class="invisible-button"
                                                          ngbTooltip="Cím megtekintése">
                                                      <i class="text-primary cursor-pointer datatable-action-icons"
                                                         data-feather="eye"></i>
                                                  </button>
                                                  <button (click)="openModal(row.id, false, false,false, accountData.id)" class="invisible-button"
                                                          ngbTooltip="Cím szerkesztése">
                                                      <i class="text-primary cursor-pointer datatable-action-icons"
                                                         data-feather="edit"></i>
                                                  </button>
                                                  <button (click)="deleteData(row.id)" class="invisible-button"
                                                          ngbTooltip="Cím törlése">
                                                      <i class="text-primary cursor-pointer datatable-action-icons"
                                                         data-feather="x-octagon"></i>
                                                  </button>
                                              </div>
                                              <ng-template #elseBlockActions ngx-datatable-cell-template>
                                                  <button (click)="openModal(row.id, false, true, false, accountData.id)" class="invisible-button"
                                                          ngbTooltip="Ez a felhasználó törölt! Megtekintés.">
                                                      <i class="text-primary cursor-pointer datatable-action-icons"
                                                         data-feather="eye"></i>
                                                  </button>
                                              </ng-template>
                                          </div>
                                      </ng-template>
                                  </ngx-datatable-column>
                              </ngx-datatable>
                              <!--/ list -->

                              <!-- form -->
                              <!--                    <form class="validate-form">-->
                              <!--                      <div class="row">-->
                              <!--                        <div class="col-12">-->
                              <!--                          <div class="form-group">-->

                              <!--                          </div>-->
                              <!--                        </div>-->
                              <!--                        <div class="col-12 col-sm-6">-->
                              <!--                          <div class="form-group">-->

                              <!--                          </div>-->
                              <!--                        </div>-->

                              <!--                        <div class="col-12">-->
                              <!--                          <button type="submit" class="btn btn-primary mt-1 mr-1" rippleEffect>Adatok mentése</button>-->
                              <!--                          <button type="reset" class="btn btn-outline-secondary mt-1" rippleEffect>Mégsem</button>-->
                              <!--                        </div>-->
                              <!--                      </div>-->
                              <!--                    </form>-->
                              <!--/ form -->
                          </div>
                      </div>
                  </ng-template>
              </li>

<!--            <li class="nav-item" ngbNavItem="divisions">-->
<!--                  <a-->
<!--                          ngbNavLink-->
<!--                          class="nav-link d-flex py-75"-->
<!--                          id="account-pill-divisions"-->
<!--                          data-toggle="pill"-->
<!--                          href="#divisions"-->
<!--                          aria-expanded="false"-->
<!--                  >-->
<!--                      <i data-feather="globe" class="font-medium-3 mr-1"></i>-->
<!--                      <span class="font-weight-bold">Tagozatok</span>-->
<!--                  </a>-->
<!--                  <ng-template ngbNavContent>-->
<!--                      <div class="card">-->
<!--                          <div class="card-body">-->
<!--                              &lt;!&ndash; form &ndash;&gt;-->
<!--                              <form class="validate-form">-->
<!--                                  <div class="row">-->
<!--                                      <div class="col-12">-->
<!--                                          <div class="form-group">-->

<!--                                          </div>-->
<!--                                      </div>-->
<!--                                      <div class="col-12 col-sm-6">-->
<!--                                          <div class="form-group">-->

<!--                                          </div>-->
<!--                                      </div>-->

<!--                                      <div class="col-12">-->
<!--                                          <button type="submit" class="btn btn-primary mt-1 mr-1" rippleEffect>Adatok mentése</button>-->
<!--                                          <button type="reset" class="btn btn-outline-secondary mt-1" rippleEffect>Mégsem</button>-->
<!--                                      </div>-->
<!--                                  </div>-->
<!--                              </form>-->
<!--                              &lt;!&ndash;/ form &ndash;&gt;-->
<!--                          </div>-->
<!--                      </div>-->
<!--                  </ng-template>-->
<!--              </li>-->

<!--            <li class="nav-item" ngbNavItem="sectors">-->
<!--                  <a-->
<!--                          ngbNavLink-->
<!--                          class="nav-link d-flex py-75"-->
<!--                          id="account-pill-sectors"-->
<!--                          data-toggle="pill"-->
<!--                          href="#sectors"-->
<!--                          aria-expanded="false"-->
<!--                  >-->
<!--                      <i data-feather="columns" class="font-medium-3 mr-1"></i>-->
<!--                      <span class="font-weight-bold">Ágazatok</span>-->
<!--                  </a>-->
<!--                  <ng-template ngbNavContent>-->
<!--                      <div class="card">-->
<!--                          <div class="card-body">-->
<!--                              &lt;!&ndash; form &ndash;&gt;-->
<!--                              <form class="validate-form">-->
<!--                                  <div class="row">-->
<!--                                      <div class="col-12">-->
<!--                                          <div class="form-group">-->

<!--                                          </div>-->
<!--                                      </div>-->
<!--                                      <div class="col-12 col-sm-6">-->
<!--                                          <div class="form-group">-->

<!--                                          </div>-->
<!--                                      </div>-->

<!--                                      <div class="col-12">-->
<!--                                          <button type="submit" class="btn btn-primary mt-1 mr-1" rippleEffect>Adatok mentése</button>-->
<!--                                          <button type="reset" class="btn btn-outline-secondary mt-1" rippleEffect>Mégsem</button>-->
<!--                                      </div>-->
<!--                                  </div>-->
<!--                              </form>-->
<!--                              &lt;!&ndash;/ form &ndash;&gt;-->
<!--                          </div>-->
<!--                      </div>-->
<!--                  </ng-template>-->
<!--              </li>-->

<!--            <li class="nav-item" ngbNavItem="workplaces">-->
<!--                  <a-->
<!--                          ngbNavLink-->
<!--                          class="nav-link d-flex py-75"-->
<!--                          id="account-pill-workplaces"-->
<!--                          data-toggle="pill"-->
<!--                          href="#workplaces"-->
<!--                          aria-expanded="false"-->
<!--                  >-->
<!--                      <i data-feather="briefcase" class="font-medium-3 mr-1"></i>-->
<!--                      <span class="font-weight-bold">Munkahelyek</span>-->
<!--                  </a>-->
<!--                  <ng-template ngbNavContent>-->
<!--                      <div class="card">-->
<!--                          <div class="card-body">-->
<!--                              &lt;!&ndash; form &ndash;&gt;-->
<!--                              <form class="validate-form">-->
<!--                                  <div class="row">-->
<!--                                      <div class="col-12">-->
<!--                                          <div class="form-group">-->

<!--                                          </div>-->
<!--                                      </div>-->
<!--                                      <div class="col-12 col-sm-6">-->
<!--                                          <div class="form-group">-->

<!--                                          </div>-->
<!--                                      </div>-->

<!--                                      <div class="col-12">-->
<!--                                          <button type="submit" class="btn btn-primary mt-1 mr-1" rippleEffect>Adatok mentése</button>-->
<!--                                          <button type="reset" class="btn btn-outline-secondary mt-1" rippleEffect>Mégsem</button>-->
<!--                                      </div>-->
<!--                                  </div>-->
<!--                              </form>-->
<!--                              &lt;!&ndash;/ form &ndash;&gt;-->
<!--                          </div>-->
<!--                      </div>-->
<!--                  </ng-template>-->
<!--              </li>-->

          </ul>
        </div>
        <div [ngbNavOutlet]="navVertical" class="col-12 col-md-9"></div>
      </div>
    </section>
    <!--/ account setting page -->
  </div>
</div>
