export const locale = {
    lang: 'en',
    data: {
        MEMBER: {
            ACTIONS: 'Actions',
            ADD_NEW_RECORDS: 'New address',
            EXPORT_CSV: 'Export CSV',
            SHOW: 'Show',
            ENTRIES: 'entries',
            SEARCH: 'Search',
            SEARCH_TYPE: 'Name or Member Id',
        },
        TABLE_HEADER: {
            ID: 'Id',
            FULL_NAME: 'Name',
            ADDRESS_TYPE: 'Type',
            ZIP_CODE: 'Zip code',
            COUNTRY: 'Country',
            CITY: 'City',
            ADDRESS: 'Address',
            STREET: 'Street',
            NUMBER: 'Number',
            BUILDING: 'Building',
            STAIRCASE: 'Staircase',
            FLOOR: 'Floor',
            DOOR: 'Door',
            CREATED_AT: 'Created at',
            UPDATED_AT: 'Updated at',
            DELETED_AT: 'Deleted at',
            ACTIONS: 'Actions',
            ADDRESS_INFORMATIONS: 'Address informations',
        }
    }
};
