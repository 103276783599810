<div class="card profile-main-card">
    <div class="heading-elements heading-elements-profile">
        <ul class="list-inline mb-0">
            <li class="ng-star-inserted"><a (click)="activeModal.close(null)"><i class="feather icon-x" ></i></a></li>
        </ul>
    </div>
    <!-- Title -->
    <div class="col-lg-12 col-md-12 col-12 row-title">
        <h3 class="card-title member-edit">{{ member.id ? viewOnly ? "Tag megtekintése" : "Tag szerkesztése" : "Tag hozzáadása" }}</h3>
    </div>
    <!-- Profile -->
    <div *ngIf="member.id" class="col-lg-12 col-md-12 col-12">
        <div class="card card-profile member-profile">
            <div class="card-body">
                <div class="profile-image-wrapper">
                    <div class="profile-image">

                            <div *ngIf="member.avatar; else customAvatar">
                                <div class="avatar">
                                    <img src="{{ frontendUrl + '/profile_pics/' + member.avatar }}" alt="Profile Picture" />
                                </div>
                            </div>

                        <ng-template #customAvatar>
                            <div class="avatar avatar-member mr-1 ml-0 bg-light-primary no-margin">
                                <div class="avatar-content avatar-content-100">{{ member.lastname + ' ' + member.firstname | initials }}</div>
                            </div>
                        </ng-template>
                    </div>
                    <button *ngIf="member.avatar_id" type="button" rippleEffect class="btn btn-outline-secondary mb-2"
                            (click)="deleteProfilepic(member.avatar_id)">Profilkép Törlése</button>
                </div>
                <h3>{{ member.name_prefix }} {{ member.lastname }} {{ member.firstname }}</h3>
                <h6 class="text-muted">
                    {{ member.role_id == 1 ? 'Adminisztrátor' : member.role_id == 2 ? 'Szerkesztő' : 'Tag' }}
                </h6>
                <div
                        [ngClass]="{
                                    'badge-light-danger': member.deleted_at != null,
                                    'badge-light-success': member.status == 'active',
                                    'badge-light-warning': member.status == 'inactive'
                                }"
                     class="badge badge-pill"
                     ngbTooltip="{{ member.status == 'active' ? 'Ez a felhasználó jelenleg AKTÍV, be tud jelentkezni a rendszerbe.' : 'Ez a felhasználó jelenleg INAKTÍV, nem tud bejelentkezni a rendszerbe.' }}"
                     placement="top"
                >
                    {{ member.deleted_at != null ? 'Törölt' : member.status == 'active' ? 'Aktív' : member.status == 'inactive' ? 'Inaktív' : 'Nincs státusz' }}
                </div>
                <hr class="mb-2" />
            </div>
        </div>
    </div>
    <!-- End Profile -->

    <!-- Deleted Member -->
    <div *ngIf="member.deleted_at != null" class="col-lg-9 col-md-12 col-12 deleted-member">
        <div class="card card-transaction border-danger">
            <div class="card-header">
                <h4 class="card-title danger">Ez a felhasználó törölt!</h4>
            </div>
            <div class="card-body">
                <div class="transaction-item">
                    <div class="media">
                        <div class="avatar bg-light-danger rounded">
                            <div class="avatar-content">
                                <i data-feather="calendar" class="avatar-icon font-medium-3"></i>
                            </div>
                        </div>
                        <div class="media-body">
                            <h6 class="transaction-title">A törlés ideje:</h6>
                        </div>
                    </div>
                    <div class="font-weight-bolder text-danger">{{ member.deleted_at }}</div>
                </div>
                <div class="transaction-item">
                    <div class="media">
                        <div class="avatar bg-light-danger rounded">
                            <div class="avatar-content">
                                <i data-feather="file-text" class="avatar-icon font-medium-3"></i>
                            </div>
                        </div>
                        <div class="media-body">
                            <h6 class="transaction-title">A törlés oka:</h6>
                        </div>
                    </div>
                    <div class="font-weight-bolder text-danger">
                        <input class="form-control ng-untouched ng-pristine" [disabled]="deletionIsDisabled" id="deletion_reason" [(ngModel)]="member.deletion_reason" style="display: inline; width:85%; margin-right: 5px;" />
                        <button (click)="toggleDisable()" class="invisible-button" ngbTooltip="Törlés okának megadása" style="display: inline">
                            <i class="text-primary cursor-pointer datatable-action-icons" data-feather="edit"></i>
                        </button>
                    </div>
                </div>
                <div class="transaction-item">
                    <button (click)="setDeletionReason()" class="btn btn-primary btn-block" tabindex="2" rippleEffect>Módosít</button>
                </div>

            </div>
        </div>
    </div>
    <!-- End Deleted Member -->

    <!-- Form - Personal Data -->
    <div class="col-lg-12 col-md-12 col-12">
        <form class="form form-horizontal member-edit" (ngSubmit)="onSubmit(MemberValidationForm.form.valid);" #MemberValidationForm="ngForm">
            <div class="row">
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-lg-1 col-md-2 col-sm-2">
                            <div class="avatar bg-light-info mr-2">
                                <div class="avatar-content">
                                    <i data-feather="user" class="avatar-icon"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-11 col-md-10 col-sm-10">
                            <h4 class="card-title">{{ 'TABLE_HEADER.PERSONAL_INFORMATIONS' | translate }}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <fieldset [disabled]="viewOnly">
            <div *ngIf="member.id" class="row">
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>{{ 'TABLE_HEADER.MEMBER_ID' | translate }}</label>
                        </div>
                        <div class="col-sm-9">
                            <div class="input-group input-group-merge">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <span [data-feather]="'shield'"></span>
                                    </span>
                                </div>
                                <input
                                        autofocus
                                        id="member_id"
                                        type="text"
                                        name="member_id"
                                        placeholder="{{ 'TABLE_HEADER.MEMBER_ID' | translate }}"
                                        class="form-control"
                                        tabindex="1"
                                        disabled
                                        [(ngModel)]="member.member_id"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>{{ 'TABLE_HEADER.EMAIL' | translate }}</label>
                        </div>
                        <div class="col-sm-9">
                            <div class="input-group input-group-merge">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" [class.error]="MemberValidationForm.submitted && TDEmailRef.invalid">
                                        <span [data-feather]="'mail'"></span>
                                    </span>
                                </div>
                                <input
                                        id="email"
                                        type="text"
                                        name="email"
                                        placeholder="{{ 'TABLE_HEADER.EMAIL' | translate }}"
                                        class="form-control"
                                        [class.error]="MemberValidationForm.submitted && TDEmailRef.invalid"
                                        tabindex="2"
                                        ngModel #TDEmailRef="ngModel"
                                        required
                                        pattern="([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})"
                                        [(ngModel)]="member.email"
                                />
                            </div>
                        </div>
                        <div class="col-sm-9 offset-sm-3">
                            <span *ngIf="MemberValidationForm.submitted && TDEmailRef.invalid" class="invalid-form">
                                <small class="form-text text-danger">Érvénytelen E-mail cím!</small>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>{{ 'TABLE_HEADER.NAME_PREFIX' | translate }}</label>
                        </div>
                        <div class="col-sm-9">
                            <div class="input-group input-group-merge">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><span [data-feather]="'user'"></span></span>
                                </div>
                                <input
                                        id="name_prefix"
                                        type="text"
                                        class="form-control"
                                        name="name_prefix"
                                        tabindex="3"
                                        placeholder="{{ 'TABLE_HEADER.NAME_PREFIX' | translate }}"
                                        [(ngModel)]="member.name_prefix"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>{{ 'TABLE_HEADER.LASTNAME' | translate }}</label>
                        </div>
                        <div class="col-sm-9">
                            <div class="input-group input-group-merge">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" [class.error]="MemberValidationForm.submitted && TDLastnameRef.invalid">
                                        <span [data-feather]="'user'"></span>
                                    </span>
                                </div>
                                <input
                                        id="lastname"
                                        type="text"
                                        name="lastname"
                                        placeholder="{{ 'TABLE_HEADER.LASTNAME' | translate }}"
                                        class="form-control"
                                        [class.error]="MemberValidationForm.submitted && TDLastnameRef.invalid"
                                        tabindex="4"
                                        ngModel #TDLastnameRef="ngModel"
                                        required
                                        [(ngModel)]="member.lastname"
                                />
                            </div>
                        </div>
                        <div class="col-sm-9 offset-sm-3">
                            <span *ngIf="MemberValidationForm.submitted && TDLastnameRef.invalid" class="invalid-form">
                                <small class="form-text text-danger">A 'Vezetéknév' kitöltése kötelező!</small>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>{{ 'TABLE_HEADER.FIRSTNAME' | translate }}</label>
                        </div>
                        <div class="col-sm-9">
                            <div class="input-group input-group-merge">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" [class.error]="MemberValidationForm.submitted && TDFirstnameRef.invalid">
                                        <span [data-feather]="'user'"></span>
                                    </span>
                                </div>
                                <input
                                        id="firstname"
                                        type="text"
                                        name="firstname"
                                        placeholder="{{ 'TABLE_HEADER.FIRSTNAME' | translate }}"
                                        class="form-control"
                                        [class.error]="MemberValidationForm.submitted && TDFirstnameRef.invalid"
                                        tabindex="5"
                                        ngModel #TDFirstnameRef="ngModel"
                                        required
                                        [(ngModel)]="member.firstname"
                                />
                            </div>
                        </div>
                        <div class="col-sm-9 offset-sm-3">
                            <span *ngIf="MemberValidationForm.submitted && TDFirstnameRef.invalid" class="invalid-form">
                                <small class="form-text text-danger">A 'Keresztnév' kitöltése kötelező!</small>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>{{ 'TABLE_HEADER.BIRTH_NAME' | translate }}</label>
                        </div>
                        <div class="col-sm-9">
                            <div class="input-group input-group-merge">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><span [data-feather]="'user'"></span></span>
                                </div>
                                <input
                                        id="birth_name"
                                        type="text"
                                        class="form-control"
                                        name="birth_name"
                                        tabindex="6"
                                        placeholder="{{ 'TABLE_HEADER.BIRTH_NAME' | translate }}"
                                        [(ngModel)]="member.birth_name"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>{{ 'TABLE_HEADER.MOTHERS_NAME' | translate }}</label>
                        </div>
                        <div class="col-sm-9">
                            <div class="input-group input-group-merge">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><span [data-feather]="'users'"></span></span>
                                </div>
                                <input
                                        id="mothers_name"
                                        type="text"
                                        class="form-control"
                                        name="mothers_name"
                                        tabindex="7"
                                        placeholder="{{ 'TABLE_HEADER.MOTHERS_NAME' | translate }}"
                                        [(ngModel)]="member.mothers_name"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>{{ 'TABLE_HEADER.BIRTH_PLACE' | translate }}</label>
                        </div>
                        <div class="col-sm-9">
                            <div class="input-group input-group-merge">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><span [data-feather]="'map-pin'"></span></span>
                                </div>
                                <input
                                        id="birth_place"
                                        type="text"
                                        class="form-control"
                                        name="birth_place"
                                        tabindex="8"
                                        placeholder="{{ 'TABLE_HEADER.BIRTH_PLACE' | translate }}"
                                        [(ngModel)]="member.birth_place"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>{{ 'TABLE_HEADER.BIRTH_DATE' | translate }}</label>
                        </div>
                        <div class="col-sm-9">
                            <div class="input-group input-group-merge">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><span [data-feather]="'calendar'"></span></span>
                                </div>
                                <input class="form-control" placeholder="éééé-hh-nn" name="birth_date"
                                       [(ngModel)]="BirthDateDPdata" ngbDatepicker [dayTemplate]="customDay" [markDisabled]="isDisabled"
                                       #BirthDateDP="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary feather icon-calendar" (click)="BirthDateDP.toggle()"
                                            type="button" rippleEffect></button>
                                </div>
                                <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled"
                                             let-focused="focused">
                                    <span class="custom-day" [class.weekend]="isWeekend(date)" [class.focused]="focused"
                                          [class.bg-primary]="selected" [class.hidden]="date.month !== currentMonth" [class.text-muted]="disabled">
                                      {{ date.day }}
                                    </span>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>{{ 'TABLE_HEADER.PHONE' | translate }}</label>
                        </div>
                        <div class="col-sm-9">
                            <div class="input-group input-group-merge">
                                <span class="input-group-text">
                                    <span [data-feather]="'phone'"></span>
                                </span>
                                <input
                                        id="phone"
                                        type="text"
                                        name="phone"
                                        tabindex="9"
                                        class="form-control"
                                        placeholder="{{ 'TABLE_HEADER.PHONE' | translate }}"
                                        [(ngModel)]="member.phone"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>{{ 'TABLE_HEADER.TAX_ID_NUMBER' | translate }}</label>
                        </div>
                        <div class="col-sm-9">
                            <div class="input-group input-group-merge">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><span [data-feather]="'file-text'"></span></span>
                                </div>
                                <input
                                        id="tax_id_number"
                                        type="text"
                                        name="tax_id_number"
                                        tabindex="10"
                                        class="form-control"
                                        placeholder="{{ 'TABLE_HEADER.TAX_ID_NUMBER' | translate }}"
                                        [(ngModel)]="member.tax_id_number"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>{{ 'TABLE_HEADER.PRIME_NUMBER' | translate }}</label>
                        </div>
                        <div class="col-sm-9">
                            <div class="input-group input-group-merge">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><span [data-feather]="'file-text'"></span></span>
                                </div>
                                <input
                                        id="prime_number"
                                        type="text"
                                        class="form-control"
                                        name="prime_number"
                                        tabindex="11"
                                        placeholder="{{ 'TABLE_HEADER.PRIME_NUMBER' | translate }}"
                                        [(ngModel)]="member.prime_number"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br><br>
            <!-- ********************* TAGOZATOK ********************* -->
            <div class="row">
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-lg-1 col-md-2 col-sm-2">
                            <div class="avatar bg-light-info mr-2">
                                <div class="avatar-content">
                                    <i data-feather="globe" class="avatar-icon"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-11 col-md-10 col-sm-10">
                            <h4 class="card-title mb-0">{{ 'TABLE_HEADER.USER_DIVISIONS' | translate }}</h4>
                            <div class="demo-inline-spacing border rounded mb-3 pl-1">
                                <div *ngFor="let division of divisiondata; let d=index" class="custom-control custom-checkbox mt-1 mb-1">
                                    <input [checked]="isCheckedDivision(division.id)"
                                           type="checkbox" class="custom-control-input" [value]="division.id"
                                           id="division-{{division.id}}" (change)="onCheckChangeDivision(division.id, $event)"/>
                                    <label class="custom-control-label" for="division-{{division.id}}">{{division.division_name}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- ********************* MUNKAHELYEK ********************* -->
            <div class="row">
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-lg-1 col-md-2 col-sm-2">
                            <div class="avatar bg-light-info mr-2">
                                <div class="avatar-content">
                                    <i data-feather="briefcase" class="avatar-icon"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-11 col-md-10 col-sm-10">
                            <h4 class="card-title mb-0">{{ 'TABLE_HEADER.USER_WORKPLACES' | translate }}</h4>
                            <div class="demo-inline-spacing border rounded mb-3 pl-1">
                                <div *ngFor="let workplace of workplacedata; let w=index" class="custom-control custom-checkbox mt-1 mb-1">
                                    <input [checked]="isCheckedWorkplace(workplace.id)"
                                           type="checkbox" class="custom-control-input" [value]="workplace.id"
                                           id="workplace-{{workplace.id}}" (change)="onCheckChangeWorkplace(workplace.id, $event)"/>
                                    <label class="custom-control-label" for="workplace-{{workplace.id}}">{{workplace.workplace_name}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- ********************* ÁGAZATOK ********************* -->
            <div class="row">
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-lg-1 col-md-2 col-sm-2">
                            <div class="avatar bg-light-info mr-2">
                                <div class="avatar-content">
                                    <i data-feather="columns" class="avatar-icon"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-11 col-md-10 col-sm-10">
                            <h4 class="card-title mb-0">{{ 'TABLE_HEADER.USER_SECTORS' | translate }}</h4>
                            <div class="demo-inline-spacing border rounded mb-3 pl-1">
                                <div *ngFor="let sector of sectordata; let s=index" class="custom-control custom-checkbox mt-1 mb-1">
                                    <input [checked]="isCheckedSector(sector.id)"
                                           type="checkbox" class="custom-control-input" [value]="sector.id"
                                           id="sector-{{sector.id}}" (change)="onCheckChangeSector(sector.id, $event)"/>
                                    <label class="custom-control-label" for="sector-{{sector.id}}">{{sector.sector_name}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            </fieldset>
            <br><br>
            <div class="col-sm-9 offset-sm-3">
                <button tabindex="12" type="submit" rippleEffect class="btn btn-primary mr-1" [disabled]="viewOnly">Beküld</button>
                <button tabindex="13" type="button" rippleEffect class="btn btn-outline-secondary" (click)="activeModal.close(null)">Mégsem</button>
            </div>
        </form>
    </div>
    <!-- End Form - Personal Data -->
</div>
