import { Role } from './role';

export class User {
  "id": number;
  "member_id": number;
  "email": string;
  "email_verified_at": string;
  "change_password": number;
  "status": string;
  "account_locked": number;
  "name_prefix": string;
  "lastname": string;
  "firstname": string;
  "fullname" : string;
  "birth_name": string;
  "mothers_name": string;
  "birth_place": string;
  "birth_date": string;
  "tax_id_number": string;
  "phone": string;
  "prime_number": string;
  "created_at": string;
  "updated_at": string;
  "deleted_at": string;
  "deletion_reason": string;
  "role": Role;
  "role_id": number;
  "token": string;
  "avatar": string;
  "avatar_id": number;
  "divisions"?: Array<{}>;
  "sectors"?: Array<{}>;
  "workplaces"?: Array<{}>;
  "addresses"?: Array<{}>;
}
