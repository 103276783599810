export const locale = {
    lang: 'hu',
    data: {
        ADDRESS: {
            ACTIONS: 'Műveletek',
            ADD_NEW_RECORDS: 'Új cím',
            EXPORT_CSV: 'Exportálás CSV-be',
            SHOW: 'Mutat',
            ENTRIES: 'bejegyzést',
            SEARCH: 'Keresés',
            SEARCH_TYPE: 'Név vagy Irányítószám',
        },
        TABLE_HEADER: {
            ID: 'Id',
            FULL_NAME: 'Név',
            ADDRESS_TYPE: 'Típus',
            ZIP_CODE: 'Irsz',
            ZIP_CODE_LONG: 'Irányítószám',
            COUNTRY: 'Ország',
            CITY: 'Város',
            ADDRESS: 'Cím',
            STREET: 'Utca',
            NUMBER: 'Házszám',
            BUILDING: 'Épület',
            STAIRCASE: 'Lépcsőház',
            FLOOR: 'Emelet',
            DOOR: 'Ajtó',
            CREATED_AT: 'Létrehozva',
            UPDATED_AT: 'Módosítva',
            DELETED_AT: 'Törölve',
            ACTIONS: 'Műveletek',
            ADDRESS_INFORMATIONS: 'A cím adatai',
            CHOOSE_MEMBERS: 'Kérem, válasszon egy Tagot!',
            SEARCH_HELP: '',
        }
    }
};
