import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { CoreConfigService } from '@core/services/config.service';
import {NgbCalendar, NgbDate, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {AuthenticationService} from "../../../../auth/service";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";

@Component({
  selector: 'app-auth-register-v1',
  templateUrl: './auth-register-v1.component.html',
  styleUrls: ['./auth-register-v1.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthRegisterV1Component implements OnInit {
  // Public
  public coreConfig: any;
  public passwordTextType: boolean;
  public registerForm: UntypedFormGroup;
  public submitted = false;
  public errorMessage = '';
  public code;
  /*  ***** Custom calendar  ***** */
  public BirthDateDPdata: NgbDateStruct;
  isWeekend = (date: NgbDate) => this._calendar.getWeekday(date) >= 6;
  isDisabled = (date: NgbDate, current: { month: number; year: number }) =>
    date.month !== current.month;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   * @param {UntypedFormBuilder} _formBuilder
   * @param _authService
   * @param _calendar
   * @param _toastrService
   * @param _router
   */
  constructor(
    private _coreConfigService: CoreConfigService,
    private _formBuilder: UntypedFormBuilder,
    private _authService: AuthenticationService,
    private _calendar: NgbCalendar,
    private _toastrService: ToastrService,
    private _router: Router) {
    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  /**
   * Toggle password
   */
  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  /**
   * On Submit
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    this._authService.register(this.f.email.value, this.f.password.value, this.f.name_prefix.value, this.f.lastname.value,
      this.f.firstname.value, this.f.birth_name.value, this.f.mothers_name.value, this.f.birth_place.value,
      this.parseDateStructtoDateStr(this.BirthDateDPdata), this.f.phone.value, this.f.tax_id_number.value,
      this.f.prime_number.value).subscribe(
      () => {
        this._router.navigateByUrl('/login').then(() => setTimeout(() => {
          this._toastrService.success(
            'Küldtünk egy regisztrációt megerősítő emailt, a megadott E-mail címre. Kérlek, ellenőrizd a postafiókodat!',
            'Sikeres regisztráció',
            { toastClass: 'toast ngx-toastr', closeButton: true, disableTimeOut: true}
          );
        }, 200));
      },
      err => {
        this.errorMessage = err.message;
        this.code = err.code;
        if (this.code === 4001)
          this.errorMessage = 'Ezzel az E-mail címmel már regisztrált valaki a CRM rendszerbe!';
        else if (this.code === 4002)
          this.errorMessage = 'Hiba történt az adatok mentése közben!';

        setTimeout(() => {
          this._toastrService.error(
            this.errorMessage,
            'Hiba történt a regisztrációkor',
            { toastClass: 'toast ngx-toastr', closeButton: true, disableTimeOut: true}
          );
        }, 200);
      }
    );
  }

  parseDateStructtoDateStr(date: NgbDateStruct | null): string | null {
    return date ? date.year + '-' + date.month + '-' + date.day : null;
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------
  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  startDate: NgbDateStruct;

  /**
   * On init
   */
  ngOnInit(): void {
    this.minDate = { year: 1900, month: 1, day: 1 };
    this.maxDate = { year: 2100, month: 1, day: 1 };
    this.startDate = { year: 1980, month: 1, day: 1 };

    this.registerForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.pattern]],
      name_prefix: [],
      lastname: ['', [Validators.required]],
      firstname: ['', [Validators.required]],
      birth_name: [],
      mothers_name: [],
      birth_place: [],
      birth_date: [],
      phone: [],
      tax_id_number: [],
      // Módosítva egyszerűsített regisztráció miatt 2023.07.05
      // phone: ['', [Validators.required]],
      // tax_id_number: ['', [Validators.required]],
      prime_number: [],
      accept_privacy_policy: ['', [Validators.requiredTrue]]
    });

    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });

  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
