import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Division} from "../../../auth/models/division";
import {DivisionService} from "../division.service";
import {ActivatedRoute} from "@angular/router";

@Component({
	selector: 'app-division-edit.edit-modal',
	templateUrl: './division-edit-modal-form.component.html',
	styleUrls: ['./division-edit-modal-form.component.scss']
})

export class DivisionEditModalFormComponent implements OnInit {
	@Input() public id: number;
	@Input() public isAddNew: boolean;

	private data: any;
	private result: any;
	public division = new Division();

	constructor(
		private route: ActivatedRoute,
		private divisionService: DivisionService,
		public activeModal: NgbActiveModal,
	) {
	}

	ngOnInit() {
		if (!this.isAddNew) {
			this.getDivision();
		}
	}

	onSubmit(isValid) {
		// stop here if form is invalid
		if (!isValid) {
			return;
		}

		if (this.division && this.division.id) {
			// *** Update *** //
			this.divisionService.update(this.id, this.division)
				.subscribe(
					() => {
						this.result = {status: true, crudType: 'u'};
						this.activeModal.close(this.result);
					},
					(res) => {
						this.result = {status: false, message: res};
						this.activeModal.close(this.result);
					}
				);
		}
		else {
			// *** Create *** //
			this.divisionService.create(this.division)
				.subscribe(
					() => {
						this.result = {status: true, crudType: 'c'};
						this.activeModal.close(this.result);
					},
					(res) => {
						this.result = {status: false, message: res};
						this.activeModal.close(this.result);
					}
				);
		}
	}

	private getDivision() {
		this.divisionService.getDivisionById(this.id).subscribe(
			res => {
				this.data = res;
				this.division = this.data;
		})
	}
}
