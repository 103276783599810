import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CoreCommonModule } from '@core/common.module';

import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

import { UserComponent } from './user.component';
import { HomeComponent } from './home.component';
import { AuthGuard } from "../../auth/helpers";
import {Role} from "../../auth/models";

const routes = [
  {
    path: 'user',
    component: UserComponent,
    canActivate: [AuthGuard],
    //data: { animation: 'user', roles: [Role.Admin] }
    data: { animation: 'user', roles: [1, 2, 3]}
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: { animation: 'home', roles: [1, 2, 3]}
  }
];

@NgModule({
  declarations: [UserComponent, HomeComponent],
  imports: [RouterModule.forChild(routes), ContentHeaderModule, TranslateModule, CoreCommonModule],
  exports: [UserComponent, HomeComponent]
})
export class UserModule {}
