import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';

import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from "../../../environments/environment";
import {map} from "rxjs/operators";

@Injectable()
export class WorkplaceService implements Resolve<any> {
	rows: any;
	onWorkplaceChanged: BehaviorSubject<any>;

	/**
	 * Constructor
	 *
	 * @param {HttpClient} _httpClient
	 */
	constructor(private _httpClient: HttpClient) {
		// Set the defaults
		this.onWorkplaceChanged = new BehaviorSubject({});
	}

	/**
	 * Resolver
	 *
	 * @param {ActivatedRouteSnapshot} route
	 * @param {RouterStateSnapshot} state
	 * @returns {Observable<any> | Promise<any> | any}
	 */
	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		return new Promise<void>((resolve, reject) => {
			Promise.all([this.getDataTableRows()]).then(() => {
				resolve();
			}, reject);
		});
	}

	/**
	 * Get all workplace rows
	 */
	getDataTableRows(): Promise<any[]> {
		return new Promise((resolve, reject) => {
			this.index().subscribe((response: any) => {
				this.rows = response;
				this.onWorkplaceChanged.next(this.rows);
				resolve(this.rows);
			}, reject);
		});
	}


	/**
	 * Get all workplaces
	 */
	getWorkplaces() {
		return this.index({observe: 'response'})
			.pipe(map((res: any) => res.body));
	}

	/**
	 * Get workplace by Id
	 */
	getWorkplaceById(id: number) {
		return this.show(id, {observe: 'response'})
			.pipe(map((res: any) => res.body));
	}
	//
	// create(data: any): Observable<HttpResponse<any>> {
	// 	return this._httpClient.post(`${environment.apiUrl}/workplace`, data, {observe: 'response'});
	// }
	//
	// destroy(id: number): Observable<HttpResponse<object>> {
	// 	return this._httpClient.delete(`${environment.apiUrl}/workplace/${id}`, {observe: 'response'});
	// }
	//
	// update(id: number, data: any): Observable<HttpResponse<any>> {
	// 	return this._httpClient.put(`${environment.apiUrl}/workplace/${id}`, data, {observe: 'response'});
	// }

	/* ***************** API CALLS ***************** */
	index(options?:{observe: 'response'}): Observable<HttpResponse<any>> {
		return this._httpClient.get(`${environment.apiUrl}/workplace`, options);
	}

	show(id: number, options?:{observe: 'response'}): Observable<HttpResponse<any>> {
		return this._httpClient.get(`${environment.apiUrl}/workplace/${id}`, options);
	}

	create(data: any, options?:{observe: 'response'}): Observable<HttpResponse<any>> {
		return this._httpClient.post(`${environment.apiUrl}/workplace`, data, options);
	}

	update(id: number, data: any, options?:{observe: 'response'}): Observable<HttpResponse<any>> {
		return this._httpClient.put(`${environment.apiUrl}/workplace/${id}`, data, options);
	}

	destroy(id: number, options?:{observe: 'response'}): Observable<HttpResponse<any>> {
		return this._httpClient.delete(`${environment.apiUrl}/workplace/${id}`, options);
	}
}
