import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {CoreCommonModule} from '@core/common.module';
import {ContentHeaderModule} from 'app/layout/components/content-header/content-header.module';
import {SectorService} from "./sector.service";
import {AuthGuard} from "../../auth/helpers";
import {SectorComponent} from "./sector.component";
import {SectorEditModalFormComponent} from "./modal-form/sector-edit-modal-form.component";
import {RouterModule, Routes} from "@angular/router";
import {CommonModule} from "@angular/common";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {CsvModule} from "@ctrl/ngx-csv";
import {CoreCardModule} from "../../../@core/components/core-card/core-card.module";
import {BrowserModule} from "@angular/platform-browser";
import {ToastrModule} from "ngx-toastr";


const routes: Routes = [
	{
		path: 'sector',
		component: SectorComponent,
		canActivate: [AuthGuard],
		data: {animation: 'sector', roles: [1, 2]},
		resolve: {
			profile: SectorService
		}
	}
];

@NgModule({
	declarations: [SectorComponent, SectorEditModalFormComponent],
	imports: [
		CommonModule,
		BrowserModule,
		RouterModule.forChild(routes),
		NgbModule, CoreCommonModule,
		ContentHeaderModule,
		TranslateModule,
		NgxDatatableModule,
		CsvModule,
		CoreCardModule,
		ToastrModule
	],
	providers: [SectorService]
})
export class SectorModule {
}

