import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule} from '@angular/common/http';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { coreConfig } from 'app/app-config';

/* ********************************  Providers ******************************** */
import { jwtInterceptorProviders } from 'app/auth/helpers/jwt.interceptor';
import { errorInterceptorProviders } from "app/auth/helpers/error.interceptor";

/* ********************************  Components ******************************** */
import { AppComponent } from 'app/app.component';

/* ********************************  App Modules ******************************** */
import { LayoutModule } from 'app/layout/layout.module';
import { UserModule } from 'app/main/user/user.module';
import { AuthenticationModule } from "app/main/pages/authentication/authentication.module";
import { ContentHeaderModule } from "./layout/components/content-header/content-header.module";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {CsvModule} from "@ctrl/ngx-csv";
import {WorkplaceModule} from "./main/workplace/workplace.module";
import {CommonModule} from "@angular/common";
import {ToastrsModule} from "./main/extensions/toastr/toastr.module";
import {MemberModule} from "./main/member/member.module";
import {UserRoleModule} from "./main/user-role/user-role.module";
import {AccountSettingsModule} from "./main/account-settings/account-settings.module";
import {SectorModule} from "./main/sector/sector.module";
import {DivisionModule} from "./main/division/division.module";
import {AddressModule} from "./main/address/address.module";

const appRoutes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    redirectTo: '/authentication/login',
    pathMatch: 'full'
  },
  {
    path: 'register',
    redirectTo: '/authentication/register',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  }
];


@NgModule({
  declarations: [
      AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy'
    }),
    TranslateModule.forRoot(),
    CommonModule,
    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // App modules
    LayoutModule,
    AccountSettingsModule,
    UserModule,
    MemberModule,
    AddressModule,
    WorkplaceModule,
    SectorModule,
    DivisionModule,
    UserRoleModule,
    AuthenticationModule,
    ContentHeaderModule,
    NgxDatatableModule,
    CsvModule,
    ToastrsModule
  ],
  providers: [jwtInterceptorProviders, errorInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule {}
