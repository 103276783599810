import {Component, OnInit, ViewEncapsulation} from '@angular/core';

import {locale as hu} from './i18n/hu'
import {locale as en} from './i18n/en'

import {CoreTranslationService} from '@core/services/translation.service'

import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';


import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

import {CoreConfigService} from '@core/services/config.service';
import {AuthenticationService} from "../../../../auth/service";
import {StorageService} from "../../../../auth/service/storage.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";

@Component({
	selector: 'app-auth-login-v1',
	templateUrl: './auth-login-v1.component.html',
	styleUrls: ['./auth-login-v1.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AuthLoginV1Component implements OnInit {
	//  Public
	public coreConfig: any;
	public loginForm: UntypedFormGroup;
	public submitted = false;
	public passwordTextType: boolean;
	public isLoggedIn = false;
	public errorMessage = '';
	public code;
	public roles: number[] = [];
	public returnUrl: string;
	public showErrorMessage: boolean;

	// Private
	private _unsubscribeAll: Subject<any>;

	constructor(private authService: AuthenticationService,
	            private storageService: StorageService,
	            private _coreConfigService: CoreConfigService,
	            private _formBuilder: UntypedFormBuilder,
	            private _coreTranslationService: CoreTranslationService,
	            private _toastrService: ToastrService,
	            private route: ActivatedRoute,
	            private router: Router
	) {
		this._unsubscribeAll = new Subject();
		this._coreTranslationService.translate(hu, en);
		// Configure the layout
		this._coreConfigService.config = {
			layout: {
				navbar: {
					hidden: true
				},
				menu: {
					hidden: true
				},
				footer: {
					hidden: true
				},
				customizer: false,
				enableLocalStorage: false
			}
		};
	}

	// convenience getter for easy access to form fields
	get f() {
		return this.loginForm.controls;
	}

	/**
	 * Toggle password
	 */
	togglePasswordTextType() {
		this.passwordTextType = !this.passwordTextType;
	}

	/**
	 * On Submit
	 */
	onSubmit() {
		this.submitted = true;
		// stop here if form is invalid
		if (this.loginForm.invalid) {
			return;
		}
		this.showErrorMessage = false;
		this.authService.login(this.f.email.value, this.f.password.value).subscribe({
			next: data => {
				this.storageService.saveUser(data);
				this.isLoggedIn = true;
				this.roles = this.storageService.getUser().user_roles[0];
				this.router.navigateByUrl(this.returnUrl);
				return true;
			},
			error: err => {
				this.showErrorMessage = true;
				this.errorMessage = err.message;
				if (err.code === 4003)
					this.errorMessage = 'Nincs megerősítve az E-mail cím! Kérlek ellenőrizd a postafiókodat és ' +
						'erősítsd meg a regisztrációkor megadott E-mail címedet!';
				else
					this.errorMessage = 'Hibás felhasználónév vagy jelszó! Kérlek, ellenőrizd a megadott adatokat és lépj be újra!';

				setTimeout(() => {
					this._toastrService.error(
						this.errorMessage,
						'Hiba történt a bejelentkezés során!',
						{ toastClass: 'toast ngx-toastr', closeButton: true, disableTimeOut: true}
					);
				}, 200);
			}
		});
	}

	// Lifecycle Hooks
	// -----------------------------------------------------------------------------------------------------
	/**
	 * On init
	 */
	ngOnInit(): void {
		this.loginForm = this._formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required]
		});

		// Subscribe to config changes
		this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
			this.coreConfig = config;
		});

		if (this.storageService.isLoggedIn()) {
			this.isLoggedIn = true;
			this.roles = this.storageService.getUser().roles;
		}

		this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
